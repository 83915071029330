import React, { Component } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { IFields, Steps } from '../../types/state';
import { Asterisk } from './Label';
import { LanguageContext, selectTranslation } from '../../context/language';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: boolean, step: Steps, valid?: boolean) => void;
  step: Steps;
  value?: boolean;
}

interface IState {
  check?: boolean;
}

interface ICheckBoxProps {
  checked?: boolean;
}

const MainCheckContainer = styled.div`
  text-align: start;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
  margin: 0.5rem 0;
  padding: 1rem;
  width: 100%;
  max-width: 100rem;
`;

const CheckBox: StyledComponent<'div', any, ICheckBoxProps, never> & ICheckBoxProps = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 6px;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#69DB8F' : '')};
  background-image: ${(props: ICheckBoxProps) => (props.checked ? 'url(/Check.svg)' : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
`;

const LabelText = styled.p`
  position: relative;
  display: inline-block;
  color: #4a4a4a;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
  max-width: 85%;
  word-wrap: break-word;
`;

class CheckBoxField extends Component<IProps, IState> {
  public state = {
    check: false,
  };

  public fileInput?: HTMLInputElement | null;

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { value } = this.props;
    this.setState((s) => ({ ...s, check: value }));
  };

  private readonly onCheckBoxChange = () => {
    const { field, step, onValueChange = () => null } = this.props;
    const { check } = this.state;
    const newCheck = !check;
    this.setState({ check: newCheck });
    onValueChange(field.id, newCheck, step, field.required ? newCheck : true);
  };

  private readonly handleClick = () => {
    this.fileInput?.click();
  };

  public render(): JSX.Element {
    const { field } = this.props;
    const { check } = this.state;

    return (
      <LanguageContext.Consumer>
        {language => {
          const translation = selectTranslation(field.translations, language);
          const label = translation?.label || field.label;

          return (
            <MainCheckContainer>
              <CheckBox onClick={this.handleClick} checked={check}>
                <input
                  ref={(r) => (this.fileInput = r)}
                  id={`${field.id}`}
                  onChange={this.onCheckBoxChange}
                  type='checkbox'
                  checked={check}
                  style={{ display: 'none' }}
                />
              </CheckBox>
              <LabelText>
                {label}
                {field.required ? <Asterisk>*</Asterisk> : null}
              </LabelText>
            </MainCheckContainer>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default CheckBoxField;
