import { Localization } from ".";

const hat: Localization = {
  addressStreet: 'Adrès Ri (Pa gen Bwat P.O.)',
  addressAptUnit: 'Apt/Inite #',
  addressCity: 'Vil',
  addressState: 'Eta',
  addressZip: 'Kòd postal',
  ageDeclaration: 'Mwen deklare mwen gen omwen 18 an.',
  ageError: 'Ou dwe gen plis pase 18an pou kontinye',
  dateDay: 'Jounen',
  dateMonth: 'Mwa',
  dateYear: 'Ane',
  dateSelect: 'chwazi dat',
  documentUploadHeader: 'Telechaje atachman nan aplikasyon w lan',
  documentRemove: 'Retire',
  documentChooseFile1: 'Trennen & Drop oswa',
  documentChooseFile2: 'Chwazi fichye',
  documentChooseFile3: 'pou w telechaje',
  documentFormat: 'Microsoft Word, PDF, JPG, JPEG, PNG, GIF dosye jiska 5MB',
  documentError: 'Se sèlman yon dokiman ki gen fòma Microsoft Word, PDF, JPG, JPEG, PNG, GIF jiska 5MB. Tanpri eseye ankò.',
  dropdownSelect: 'Tanpri Chwazi',
  experienceRemove: 'Retire',
  experienceAgencyName: 'Non ajans lan',
  experienceStartDate: 'Dat Kòmanse',
  experienceEndDate: 'Dat fen',
  experienceDatesApproximate: 'Dat yo ka apwoksimatif',
  genderSelect: 'Endike sèks ou',
  genderMale: 'Gason',
  genderFemale: 'Fi',
  genderOther: 'Lòt',
  genderRatherNotSay: 'Pito pa di',
  unitFeet: 'pye',
  unitInches: 'pous',
  unitPounds: 'liv',
  unitHours: 'èdtan',
  transportMethodSelect: 'Chwazi Metòd transpò pi pito ou a',
  transportMethodWalking: 'ap mache',
  transportMethodPublic: 'piblik',
  transportMethodDriving: 'kondwi',
  uploadIdButton: 'Telechaje ID',
  uploadIdEligible: 'Fòm id elijib yo enkli:',
  uploadIdDrivers: 'Leta bay lisans chofè',
  uploadIdNonDrivers: 'Eta bay idantite ki pa chofè',
  uploadIdGreenCard: 'Kat Vèt Rezidan Pèmanan',
  inputEnter: 'Antre',
  prevEmployerPrompt: 'Ranpli dènye patwon ajans ou yo ak dat travay ou yo',
  ssnWhy: 'Poukisa nou bezwen sa?',
  ssnExplanation: 'Nimewo Sekirite Sosyal nesesè pou sekirite w ak pou pwouve idantite w',

  formPagePrev: 'Prev',
  formPageNext: 'Next',
  additionalInfoTitle: 'Enfòmasyon w yo',
  additionalInfoSubtitle: 'Tanpri ranpli otan ke posib nan enfòmasyon kontak sa yo',
  backgroundInfoTitle: 'Enfòmasyon sou background',
  backgroundInfoSubtitle: 'Ajoute yon fòm idantite pou montre kalifikasyon pou travay Ozetazini.',
  basicInfoTitle: 'Enfòmasyon de baz ou',
  basicInfoSubtitle: 'Pou kòmanse aplikasyon w lan tanpri antre enfòmasyon ki anba a epi klike sou "Next"',
  basicInfoRequired: '* - enfòmasyon obligatwa',
  scheduleWorkPrefTitle: 'Orè ak travay preferans',
  shiftClientPrefTitle: 'Shift ak Preferans Kliyan',
  shiftClientPrefSubtitle: 'Tanpri ranpli kèk enfòmasyon debaz pou ede nou detèmine pi bon alimèt shift yo si ou satisfè kritè travay nou yo.',
  verificationTitle: 'Nou te jwenn alimèt potansyèl moun kap bay swen.',
  verificationSubtitle: 'Tcheke imèl ou oswa mesaj tèks pou kòd inik 6 chif ou pou konfime idantite w',
  verificationResend: 'Revoye Kòd',
  welcomeTitle: 'Mèsi pou enterè ou nan yon pozisyon nan',
  welcomeGetStarted: 'Kòmanse',
  experienceTitle: 'Work Experience',
  experienceSubtitle: 'Konbyen ane eksperyans travay enpòtan ou genyen?',
};

export default hat;
