import React, { Component } from 'react';
import styled from 'styled-components';
import { ILayout } from '../backend';
import { Value } from '../types/state';
import FormPage from './FormPage';
import './WelcomePage.scss';
import { LocalizeContext } from '../context/localize';

interface IProps {
  layout?: ILayout;
  email?: Value;
  sendVerification: (vNumber: string) => void;
  resendVerification: () => void;
  error: string;
  setLanguage: (language: string) => void;
  languages: string[] | null;
}

interface IState {
  value1: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
}

const InputTxt = styled.input`
  border: none;
  height: 50px;
  background-color: #f1f1f1;
  padding-left: 1ch;
  width: 3ch;
  margin: 0 0.5rem;
  &::placeholder {
    font-weight: bold;
    font-family: Lato, sans-serif;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    border: none;
    background-color: #f1f1f1;
  }
`;

const LinkButton = styled.button`
  outline: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  border: none;
  color: #04a5d5;
  text-align: center;
  margin: 2rem;
  cursor: pointer;
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: #f16778;
  text-align: center;
  margin: 0 1rem 1rem 1rem;
`;

class VerificationPage extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    value1: '',
    value2: '',
    value3: '',
    value4: '',
    value5: '',
    value6: '',
  };

  public value1Ref?: HTMLInputElement | null;
  public value2Ref?: HTMLInputElement | null;
  public value3Ref?: HTMLInputElement | null;
  public value4Ref?: HTMLInputElement | null;
  public value5Ref?: HTMLInputElement | null;
  public value6Ref?: HTMLInputElement | null;

  public componentDidMount(): void {
    this.value1Ref?.focus();
  }

  private readonly handleSendVerification = () => {
    const { value1, value2, value3, value4, value5, value6 } = this.state;
    const { sendVerification } = this.props;

    if (value1 && value2 && value3 && value4 && value5 && value6) {
      sendVerification(`${value1}${value2}${value3}${value4}${value5}${value6}`);

      return this.setState((s) => ({
        ...s,
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        value6: '',
      }));
    }
    return;
  };

  private readonly handleResend = () => {
    this.props.resendVerification();
  };

  private readonly handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const id = e.target.id;
    switch (id) {
      case 'value1':
        return this.setState((s) => ({ ...s, value1: '' }));
      case 'value2':
        return this.setState((s) => ({ ...s, value2: '' }));
      case 'value3':
        return this.setState((s) => ({ ...s, value3: '' }));
      case 'value4':
        return this.setState((s) => ({ ...s, value4: '' }));
      case 'value5':
        return this.setState((s) => ({ ...s, value5: '' }));
      case 'value6':
        return this.setState((s) => ({ ...s, value6: '' }));
    }
  };

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const id = e.currentTarget.id;
    switch (id) {
      case 'value1':
        this.setState(
          (s) => ({ ...s, value1: value }),
          () => this.handleSendVerification()
        );
        return this.value2Ref ? this.value2Ref.focus() : null;
      case 'value2':
        this.setState(
          (s) => ({ ...s, value2: value }),
          () => this.handleSendVerification()
        );
        return this.value3Ref ? this.value3Ref.focus() : null;
      case 'value3':
        this.setState(
          (s) => ({ ...s, value3: value }),
          () => this.handleSendVerification()
        );
        return this.value4Ref ? this.value4Ref.focus() : null;
      case 'value4':
        this.setState(
          (s) => ({ ...s, value4: value }),
          () => this.handleSendVerification()
        );
        return this.value5Ref ? this.value5Ref.focus() : null;
      case 'value5':
        this.setState(
          (s) => ({ ...s, value5: value }),
          () => this.handleSendVerification()
        );
        return this.value6Ref ? this.value6Ref.focus() : null;
      case 'value6':
        return this.setState(
          (s) => ({
            ...s,
            value6: value,
          }),
          () => this.handleSendVerification()
        );
    }
  };

  private readonly handleBack = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    const id = e.currentTarget.id;
    if (key === 'Backspace') {
      switch (id) {
        case 'value1':
          return;
        case 'value2':
          return this.value1Ref ? this.value1Ref.focus() : null;
        case 'value3':
          return this.value2Ref ? this.value2Ref.focus() : null;
        case 'value4':
          return this.value3Ref ? this.value3Ref.focus() : null;
        case 'value5':
          return this.value4Ref ? this.value4Ref.focus() : null;
        case 'value6':
          return this.value5Ref ? this.value5Ref.focus() : null;
      }
    }
  };

  public render(): JSX.Element {
    const { layout, error, setLanguage, languages } = this.props;
    const { value1, value2, value3, value4, value5, value6 } = this.state;
    const localize = this.context;

    return (
      <FormPage layout={layout} valid={true} noButtons={true} setLanguage={setLanguage} languages={languages}>
        <div className='welcome-note-container'>
          <h3 className='form-header'>{localize('verificationTitle')}</h3>
          <p className='form-title'>
            {localize('verificationSubtitle')}
          </p>

          <br />
          <br />
          <br />

          {error && <ErrorText>{error}</ErrorText>}
          <div>
            <InputTxt
              id='value1'
              ref={(r) => (this.value1Ref = r)}
              type='text'
              maxLength={1}
              value={value1}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
            <InputTxt
              id='value2'
              ref={(r) => (this.value2Ref = r)}
              type='text'
              maxLength={1}
              value={value2}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
            <InputTxt
              id='value3'
              ref={(r) => (this.value3Ref = r)}
              type='text'
              maxLength={1}
              value={value3}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
            <InputTxt
              id='value4'
              ref={(r) => (this.value4Ref = r)}
              type='text'
              maxLength={1}
              value={value4}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
            <InputTxt
              id='value5'
              ref={(r) => (this.value5Ref = r)}
              type='text'
              maxLength={1}
              value={value5}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
            <InputTxt
              id='value6'
              ref={(r) => (this.value6Ref = r)}
              type='text'
              maxLength={1}
              value={value6}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              onKeyUp={this.handleBack}
            />
          </div>
          <LinkButton onClick={this.handleResend}>{localize('verificationTitle')}</LinkButton>
        </div>
      </FormPage>
    );
  }
}

export default VerificationPage;
