import * as moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import ExperienceCard, { IExperience } from './ExperienceCard';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: any, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: IExperience[];
}

interface IState {
  experiences?: IExperience[];
  buttonDisable: boolean;
  windowWidth: number;
}

const PlusButton = styled.button`
  outline: none;
  padding: 0;
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url('/CirclePlus.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
`;

class PrevEmployerComponent extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    experiences: [],
    buttonDisable: false,
    windowWidth: window.innerWidth,
  };

  private readonly handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  public componentDidMount(): void {
    this.setValuesToState();
    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
  }

  private readonly getGridColumnCount = (experienceCount?: number) => {
    if (experienceCount) {
      const { windowWidth } = this.state;
      if (windowWidth <= 800 || experienceCount === 1) {
        return 1;
      }
      if (windowWidth < 1200){
          if(experienceCount >= 2) {
            return 2;
          }
          return 1;
      }
      else {
        if(experienceCount >= 3) {
          return 3;
        }
        if(experienceCount === 2) {
          return 2;
        }
        return 1;
      }
    }
    return 1;
  };

  private readonly setValuesToState = () => {
    const { value } = this.props;
    this.setState((s) => ({ ...s, experiences: value }));
  };

  private readonly addExperience = () => {
    const { experiences } = this.state;
    const newExperiences: IExperience[] = experiences || [];
    const newAgency: IExperience = {
      id: moment.now() + newExperiences.length,
      agencyName: '',
      start: null,
      end: null,
    };
    newExperiences.push(newAgency);
    return this.setState((s) => ({
      ...s,
      experiences: newExperiences,
      buttonDisable: true,
    }));
  };

  public removeExperience = (id: number) => {
    const { field, step, onValueChange = () => null } = this.props;
    const { experiences } = this.state;
    const newExperiences = experiences.filter((e: IExperience) => e.id !== id);
    const allFieldsFiled = newExperiences.filter(
      (e: IExperience) => !e.agencyName || !e.start || !e.end
    );
    this.setState((s) => ({
      ...s,
      experiences: newExperiences,
      buttonDisable: !!allFieldsFiled.length,
    }));
    return onValueChange(field.id, newExperiences, step, true);
  };

  public handleChange = (agency: IExperience) => {
    const { field, step, onValueChange = () => null } = this.props;
    const { experiences } = this.state;
    const experience = experiences.findIndex((e: IExperience) => e.id === agency.id);
    const newExperiences: IExperience[] = experiences;
    newExperiences[experience] = agency;
    const allFieldsFiled = newExperiences.filter(
      (e: IExperience) => !e.agencyName || !e.start || !e.end
    );
    this.setState((s) => ({
      ...s,
      experiences: newExperiences,
      buttonDisable: !!allFieldsFiled.length,
    }));
    return onValueChange(field.id, newExperiences, step, true);
  };

  public render(): JSX.Element {
    const { experiences, buttonDisable } = this.state;
    const localize = this.context;

    return (
      <div className='prev-employer' style={{ padding: '1rem' }}>
        <p>{localize('prevEmployerPrompt')}</p>
        <div className='prev-employer-cards' style={experiences ? { gridTemplateColumns: `repeat(${this.getGridColumnCount(experiences.length)}, 1fr)` } : { gridTemplateColumns: '1fr' }}>
          {experiences &&
            experiences.map((e: IExperience) => (
              <ExperienceCard
                key={e.id}
                experience={e}
                id={e.id}
                removeExperience={this.removeExperience}
                handleValueChange={this.handleChange}
              />
            ))}
        </div>
        <div style={{ height: '40px' }}>
          <PlusButton disabled={buttonDisable} onClick={this.addExperience} />
        </div>
      </div>
    );
  }
}

export default PrevEmployerComponent;
