import React, { Component } from 'react';
import styled from 'styled-components';
import middleWay from '../../helpers/middleWay';
import { IFields, Steps } from '../../types/state';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: any, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: string;
}
interface IState {
  preView?: string | ArrayBuffer | null;
}

const IdUploadContainer = styled.div`
  display: grid;
  grid-column: span 1;
  grid-template-columns: 1fr;
  place-items: center;
  box-shadow: 0 0 1rem #00000040;
  border-radius: 10px;
  color: #9b9b9b;
  padding: 2rem;
  margin: 1rem 0 1rem 0;
  @media (max-width: 500px) {
    padding: 0.5rem;
  }
`;

const UploadButton = styled.button`
  outline: none;
  background-color: transparent;
  width: 162px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #c8cbd3;
  color: #9b9b9b;
  text-align: center;
  margin: 1rem;
`;

class IDUploadField extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    preView: '',
  };

  public fileInput?: HTMLInputElement | null;

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { value } = this.props;
    this.setState((s) => ({ ...s, preView: value }));
  };

  private readonly uploadID = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { field, onValueChange, step } = this.props;
    e.preventDefault();
    if (e.target?.files?.length) {
      middleWay.uploadIdDocument(e.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target?.files[0]);
      this.setState((s) => ({ ...s, preView: imageUrl }));
      onValueChange?.(field.id, imageUrl, step, true);
    }
  };

  public onUploadClick = () => this.fileInput?.click();

  public render(): JSX.Element {
    const { preView } = this.state;
    const localize = this.context;

    return (
      <IdUploadContainer className='id-uploader'>
        <input
          ref={(r) => (this.fileInput = r)}
          type='file'
          accept={'image/*'}
          capture={'camera'}
          style={{ display: 'none' }}
          onChange={this.uploadID}
        />
        {preView ? (
          <img
            alt='Id Pace holder'
            src={preView}
            style={{ maxWidth: '500px', maxHeight: '400px' }}
          />
        ) : (
          <img alt='Id Pace holder' src='/IDPlaceholder.svg' />
        )}
        <UploadButton onClick={this.onUploadClick}>{localize('uploadIdButton')}</UploadButton>
        <p style={{ color: '#4A4A4A' }}>{localize('uploadIdEligible')}</p>
        • {localize('uploadIdDrivers')}
        <br />
        • {localize('uploadIdNonDrivers')}
        <br />
        • {localize('uploadIdGreenCard')}
        <br />
      </IdUploadContainer>
    );
  }
}

export default IDUploadField;
