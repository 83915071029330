import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { IFields } from '../../types/state';
import { useTranslation } from '../../context/language';

interface IProps {
  component: IFields;
}

const HeaderComp = styled.p`
  font-size: 16px;
`;

function Header({ component }: IProps): ReactElement {
  const translation = useTranslation(component.translations);

  const { id } = component;

  const value = translation?.value || component.value;

  if (id === 'header') {
    const str: string = value?.toString() || '';
    return <ReactMarkdown>{str}</ReactMarkdown>;
  }

  return <HeaderComp>{value}</HeaderComp>;
}

export default Header;
