import React, { Component } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { IFields, Steps } from '../../types/state';
import { Asterisk } from './Label';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: boolean, step: Steps, valid?: boolean) => void;
  step: Steps;
  value?: boolean;
}

interface IState {
  check?: boolean;
}

interface ICheckBoxProps {
  checked?: boolean;
}

const MainCheckContainer = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: 30px 300px;
  place-items: center;
  padding: 1rem;
  width: 100%;
  max-width: 330px;
`;

const CheckBox: StyledComponent<'div', any, ICheckBoxProps, never> & ICheckBoxProps = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 6px;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  margin-right: 1rem;
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#69DB8F' : '')};
  background-image: ${(props: ICheckBoxProps) => (props.checked ? 'url(/Check.svg)' : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
`;

const LabelText = styled.p`
  margin: 0;
  color: #4a4a4a;
  font-weight: bold;
  font-size: 14px;
  bottom: 10px;
`;

class AgeDeclaration extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    check: false,
  };
  public fileInput?: HTMLInputElement | null;

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { value } = this.props;
    this.setState((s) => ({ ...s, check: value }));
  };

  private readonly onCheckBoxChange = () => {
    const { field, step, onValueChange = () => null } = this.props;
    const { check } = this.state;
    const newCheck = !check;
    this.setState({ check: newCheck });
    onValueChange(field.id, newCheck, step, field.required ? newCheck : true);
  };

  private readonly handleClick = () => {
    this.fileInput?.click();
  };

  public render(): JSX.Element {
    const { field } = this.props;
    const { check } = this.state;
    const localize = this.context;

    return (
      <MainCheckContainer className='age-declaration'>
        <CheckBox onClick={this.handleClick} checked={check}>
          <input
            ref={(r) => (this.fileInput = r)}
            id={`${field.id}`}
            onChange={this.onCheckBoxChange}
            type='checkbox'
            checked={check}
            style={{ display: 'none' }}
          />
        </CheckBox>
        <LabelText>
          {localize('ageDeclaration')}{field.required && <Asterisk>*</Asterisk>}
        </LabelText>
      </MainCheckContainer>
    );
  }
}

export default AgeDeclaration;
