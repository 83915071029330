import React, { Component, SyntheticEvent } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styled, { StyledComponent } from 'styled-components';
import states from '../../constants/states';
import { isValidZipCode } from '../../constants/validation';
import { IAddress, IFields, IInputValidation, Steps } from '../../types/state';
import Label from './Label';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: any, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: IAddress;
}

interface IState {
  fullAddress?: IAddress;
}

const AddressContainer = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-areas: 'street street street apt city city state zip zip';
  padding: 1rem;
  column-gap: 5px;
  width: 100%;
  grid-column: 1 / span 3;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column: 1 / span 2;
    grid-template-areas:
      'street street apt'
      'city state zip';
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'street street street'
      'apt city city'
      'state zip zip';
  }
`;

const FieldContainer = styled.div`
  margin: 1rem 0 0 0;
  grid-area: ${(props) => props.id};

  @media (min-width: 1200px) {
    ${(props) => props.id === 'street' && 'margin-right: 30px;'}
    ${(props) => props.id === 'apt' && 'margin-left: 15px;'}
    ${(props) => props.id === 'city' && 'margin-right: 15px;'}
    ${(props) => props.id === 'state' && 'margin-left: 30px;'}
  }
`;

const InputField: StyledComponent<'input', any, IInputValidation, never> &
  IInputValidation = styled.input`
  border: none;
  height: 50px;
  background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  padding: 0 1rem;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    font-weight: bold;
    font-family: Lato, sans-serif;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    border: none;
    background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  }
`;

const State = styled(Dropdown)`
  &&&& {
    font-family: Lato, sans-serif;
    border: 1px solid #c8cbd3;
    max-height: 38px;
    margin-top: 5px;
    width: 90px;
  }
  &&&& > i {
    border-left: 1px solid #c8cbd3;
    color: #c8cbd3;
  }
`;

class AddressComponent extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    fullAddress: {
      street: '',
      aptUnit: '',
      city: '',
      state: '',
      zip: '',
    },
  };

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly checkValidation = (address?: IAddress) => {
    return (
      !!address?.street.length &&
      !!address?.city.length &&
      !!address?.state.length &&
      !!address?.zip.length &&
      isValidZipCode(address?.zip)
    );
  };

  private readonly setValuesToState = () => {
    const { field, step, value, onValueChange = () => null } = this.props;
    const isValid = this.checkValidation(value);
    onValueChange(field.id, value || '', step, isValid);
    this.setState((s) => ({ ...s, fullAddress: value }));
  };

  private readonly handleValueChange = (newAddress: IAddress) => {
    const { field, step, onValueChange = () => null } = this.props;
    this.setState((s) => ({ ...s, fullAddress: newAddress }));
    const isValid = this.checkValidation(newAddress);
    return onValueChange(field.id, newAddress, step, isValid);
  };

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const id = e.target.id;
    const { fullAddress } = this.state;
    const newFullAddress = fullAddress;
    Object.assign(newFullAddress, { [id]: value });
    return this.handleValueChange(newFullAddress);
  };

  private readonly handleStateChange = (_e: SyntheticEvent, d: DropdownProps) => {
    const { fullAddress } = this.state;
    const newFullAddress = fullAddress;
    Object.assign(newFullAddress, { state: d.value });
    return this.handleValueChange(newFullAddress);
  };

  public render(): JSX.Element {
    const { fullAddress } = this.state;
    const { field } = this.props;
    const { required } = field;
    const localize = this.context;

    return (
      <AddressContainer>
        <FieldContainer id='street'>
          <Label field={{ label: localize('addressStreet'), required }} />
          <InputField
            id='street'
            type='text'
            value={fullAddress.street}
            placeholder={`${localize('inputEnter')} ${localize('addressStreet')}`}
            valid={fullAddress.street ? fullAddress.street.length > 2 : true}
            onChange={this.handleChange}
            maxLength={200}
          />
        </FieldContainer>
        <FieldContainer id='apt'>
          <Label field={{ label: localize('addressAptUnit'), required: false }} />
          <InputField
            id='aptUnit'
            type='text'
            valid={true}
            value={fullAddress.aptUnit}
            placeholder={`${localize('inputEnter')} ${localize('addressAptUnit')}`}
            onChange={this.handleChange}
          />
        </FieldContainer>
        <FieldContainer id='city'>
          <Label field={{ label: localize('addressCity'), required }} />
          <InputField
            id='city'
            type='text'
            value={fullAddress.city}
            placeholder={`${localize('inputEnter')} ${localize('addressCity')}`}
            valid={fullAddress.city ? fullAddress.city.length > 2 : true}
            onChange={this.handleChange}
          />
        </FieldContainer>
        <FieldContainer id='state'>
          <Label field={{ label: localize('addressState'), required }} />
          <div />
          <State
            selection={true}
            search={true}
            compact={true}
            noResultsMessage={'Not found'}
            options={states.map((v) => ({
              text: v.abbreviation,
              value: v.abbreviation,
            }))}
            value={fullAddress.state}
            onChange={this.handleStateChange}
          />
        </FieldContainer>
        <FieldContainer id='zip'>
          <Label field={{ label: localize('addressZip'), required }} />
          <InputField
            id='zip'
            type='text'
            value={fullAddress.zip}
            placeholder={`${localize('inputEnter')} ${localize('addressZip')}`}
            valid={fullAddress.zip ? isValidZipCode(fullAddress.zip) : true}
            onChange={this.handleChange}
          />
        </FieldContainer>
      </AddressContainer>
    );
  }
}

export default AddressComponent;
