import React from "react";
import styled from "styled-components";
import { Language } from "../../constants/languages";

const StyledButton = styled.button`
  border: none;
  border-bottom: 1px solid rgba(127, 127, 127, 0.5);
  background: none;
  display: block;
  width: 100%;
  height: 4rem;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StyledLabel = styled.div`
  display: inline-block;
  flex: 1 0;
  text-align: left;
`;

const NativeLabel = styled.div`
  display: block;
  margin-bottom: 0.2rem;
`;

const EnglishLabel = styled.div`
  display: block;
  font-size: 0.8rem;
  opacity: 0.5;
`;

const StyledArrow = styled.div`
  flex: 0 0 2rem;

  ::after {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-top: 0.2rem solid #7f7f7f;
    border-right: 0.2rem solid #7f7f7f;
    transform: rotate(45deg);
    vertical-align: middle;
  }
`;

const Flag = styled.img`
  width: 2rem;
  margin-right: 0.5rem;
`;

interface IProps {
  language: Language;
  onClick: () => void;
}

function LanguageButton({
  language,
  onClick,
}: IProps) {
  return (
    <StyledButton onClick={onClick}>
      <Flag src={`flags/${language.flagCountryCode}.png`} />
      <StyledLabel>
        <NativeLabel>{language.name}</NativeLabel>
        <EnglishLabel>{language.englishName}</EnglishLabel>
      </StyledLabel>
      <StyledArrow />
    </StyledButton>
  );
}

export default LanguageButton;
