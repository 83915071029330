// This file will always be large, but it could also always use some cleanup.
// tslint:disable: max-file-line-count
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Lottie from 'react-lottie';
import { Image } from 'semantic-ui-react';

import backend, {
  IFieldsValue,
  ILayout,
  IPages,
  IProfileParams,
  IUpdateProfileResponse,
  // IPhone
} from '../backend';
import middleWay from '../helpers/middleWay';
import * as states from '../types/state';
import { DocumentInfo, IVerificationStatus } from '../types/state';
import AdditionalInformation from './AdditionalInformation';
import animationData from './anim_cc_loading_transparent.json';
import './App.scss';
import BackgroundInfo from './BackgroundInfo';
import BasicInfo from './BasicInfo';
import FinishPage from './FinishPage';
import ScheduleWorkPreferences from './ScheduleWorkPreferences';
import ShiftClientPreferences from './ShiftClientPreferences';
import VerificationPage from './VerificationPage';
import WelcomePage from './WelcomePage';
import WorkExperience from './WorkExperience';
import LanguageSelection from './LanguageSelection';
import { fromCode } from '../constants/languages';
import { LanguageContext } from '../context/language';
import { LocalizeProvider } from '../context/localize';

interface IAppState {
  activePages: states.Steps[];
  step: states.Steps;
  valid: boolean;
  agencyUuid?: string;
  agencyName: string;
  layout?: ILayout;
  pages: IPages;
  loading?: boolean;
  verificationStatus?: IVerificationStatus;
  verificationError: string;
  preview: string | null;
  errorMessage: string;
  usingIE: boolean;
  trackingId: string | null;
  language: string | null;
  availableLanguages: string[] | null;
}

class App extends React.PureComponent<{}, IAppState> {
  constructor(props: {}) {
    super(props);

    const preview = new URLSearchParams(window.location.search).get('preview');
    const trackingId = new URLSearchParams(document.location.search).get('trackingId');

    this.state = {
      activePages: [],
      step: 'agencyWelcomeNote',
      preview,
      valid: false,
      agencyUuid: '',
      agencyName: '',
      loading: true,
      layout: {
        backgroundColor: '',
        fontSize: '',
        headerImage: '',
        logoImage: '',
      },
      pages: {},
      verificationStatus: undefined,
      verificationError: '',
      errorMessage: '',
      usingIE: false,
      trackingId,
      language: null,
      availableLanguages: null,
    };
  }

  private readonly uploadDocuments = () => {
    const { step, pages } = this.state;
    const list: Array<Promise<{ id: string; doc: DocumentInfo }>> = [];
    const documentUploadFields = pages[step].fields.filter(
      ({ type, visible }) => type === 'documentUploader' && visible
    );

    if (documentUploadFields.length) {
      documentUploadFields.forEach((field) => {
        const { value, id } = field;

        if (value) {
          (value as DocumentInfo[]).forEach((doc) => {
            const { file, status } = doc;

            if (status !== 'OK') {
              list.push(
                backend
                  .documentUpload(file.type, id)
                  .then((resp) => resp.json())
                  .then(({ url, uuid }) =>
                    fetch(url, { method: 'PUT', body: file }).then((resp) => ({
                      resp,
                      key: uuid,
                    }))
                  )
                  .then(({ resp, key }) => {
                    doc.key = key;
                    return resp.ok ? { doc, id } : Promise.reject({ doc, id });
                  })
                  .catch(() => Promise.reject({ doc, id }))
              );
            } else {
              list.push(Promise.resolve({ doc, id }));
            }
          });
        }
      });
    }

    return list;
  };

  public prevPage = () => {
    const { activePages } = this.state;
    this.setState(
      (s) => ({
        ...s,
        step: activePages[activePages.indexOf(this.state.step) - 1],
      }),
      () => this.validateRequired()
    );
  };

  public nextPage = () => {
    const { activePages, pages, step } = this.state;
    const complete = activePages.indexOf(step) + 2 === activePages.length;
    const promises = this.uploadDocuments();

    this.setState((s) => ({ ...s, loading: true }));

    Promise.all(promises)
      .then((resp) => {
        if (resp.length) {
          const documentUploadFields = pages[step].fields.filter(
            ({ type, visible }) => type === 'documentUploader' && visible
          );

          if (documentUploadFields.length) {
            documentUploadFields.forEach((field) => {
              const value = resp
                .filter(({ id }) => field.id === id)
                .map(({ doc }) => {
                  const { file } = doc;
                  doc.status = 'OK';
                  doc.fileName = file.name;
                  return doc;
                });

              this.onValueChange(field.id, value, step, true);
            });
          }
        }

        this.submitProfile(complete, false);
      })
      .catch(() => {
        this.setState((s) => ({
          ...s,
          loading: false,
          errorMessage: 'An error occurred while saving your application, please try again',
          step: activePages[activePages.indexOf(step)],
        }));
      });
  };

  public getStarted = () => {
    this.submitProfile(false, true);
  };

  private readonly filterPagesByDisciplineAndContinue = (response: IUpdateProfileResponse) => {
    const { activePages, pages, step } = this.state;

    // Modify fields of the pages to have visible = false, if discipline doesn't match
    const newPages = Object.fromEntries(
      Object.entries(pages).map(([key, obj]) => {
        const newFields = obj.fields.map((field) => {
          return (
            !field.disciplineFilter ||
            field.disciplineFilter?.includes('ALL') ||
            field.disciplineFilter?.some(item => response.welcome.disciplines?.includes(item))
          ) ? field : {...field, visible: false};
        });
        return [key, {...obj, fields: newFields}];
      })
    );

    // Creates filtered active pages, based on the field visibility
    const filteredActivePages = Object.entries(newPages).map(([key, obj]) => {
      const fields = obj.fields.filter(x => x.visible);
      return fields.length ? key : undefined
    }).filter(m => m);

    // Filter the original activePages based on the filteredActivePages
    const newActivePages = activePages.filter(x => filteredActivePages.includes(x) || x === 'verificationPage');

    const statusPending = response?.verificationStatus?.status === 'PENDING';
    const nextStep = statusPending
      ? 'verificationPage'
      : newActivePages[newActivePages.indexOf(step) + 1];

    this.setState((s) => ({
      ...s,
      activePages: newActivePages,
      pages: newPages,
      step: nextStep,
    }));
  };

  public submitProfile = (complete: boolean, start: boolean) => {
    const { activePages, step, agencyUuid, pages, trackingId } = this.state;

    const reduceInfo = (fields: states.IFields[]): IFieldsValue => {
      if (fields && fields.length) {
        return fields.reduce((f: IFieldsValue, rF: states.IFields) => {
          const newField = Object.assign({}, { [rF.id]: rF.value });
          Object.assign(f, newField);
          return f;
        }, {});
      }
      return {};
    };

    const profile = {
      token: '',
      agencyUuid,
      welcome: reduceInfo(pages.agencyWelcomeNote?.fields),
      basicInformation: reduceInfo(pages.basicInformation?.fields),
      additionalInformation: reduceInfo(pages.additionalInformation?.fields),
      workExperience: reduceInfo(pages.workExperience?.fields),
      backgroundInformation: reduceInfo(pages.backgroundInformation?.fields),
      workPreferences: reduceInfo(pages.scheduleWorkPreferences?.fields),
      shiftPreferences: reduceInfo(pages.shiftClientPreferences?.fields),
      campaignToken: trackingId,
      pages,
    };
    const createProfile = step === 'agencyWelcomeNote';

    backend
      .updateProfile(profile, createProfile, complete)
      .then((data: IUpdateProfileResponse) => {
        if (start && data.welcome?.disciplines?.length) {
          this.filterPagesByDisciplineAndContinue(data);
        } else {
          const statusPending = data?.verificationStatus?.status === 'PENDING';
          const nextStep = statusPending
            ? 'verificationPage'
            : activePages[activePages.indexOf(step) + 1];

          this.setState((s) => ({
            ...s,
            loading: false,
            errorMessage: '',
            verificationStatus: statusPending ? data.verificationStatus : undefined,
            step: nextStep,
          }));
        }
      })
      .catch(() => {
        this.setState((s) => ({
          ...s,
          loading: false,
          errorMessage: 'An error occurred while saving your application, please try again',
        }));
        return this.prevPage();
      });
  };

  private readonly validateRequired = () => {
    const { step } = this.state;
    const page = this.state.pages[step];
    const requiredFields = page?.fields?.filter((f) => f.visible && f.required);
    const filedFields = requiredFields?.filter((f) => f.isValid && (!Array.isArray(f.value) || f.value.length > 0));

    if (requiredFields?.length === filedFields?.length) {
      return this.setState((s) => ({ ...s, valid: true }));
    }
    return this.setState((s) => ({ ...s, valid: false }));
  };

  public onValueChange = (field: string, value: any, step: states.Steps, valid?: boolean, error?: boolean) => {
    const newPages = { ...this.state.pages };
    if (!newPages || error) return;
    if (newPages) {
      const fieldIndex = newPages[step].fields?.findIndex((f) => {
        if (f.id === field) {
          return f;
        }
        return null;
      });
      const fieldToChange = newPages[step].fields[fieldIndex];
      const newValue = Object.assign(fieldToChange, { value, isValid: valid });
      newPages[step].fields[fieldIndex] = newValue;
      this.setState((s) => ({ ...s, pages: newPages }));
    }
    return this.validateRequired();
  };

  private readonly startSession = (domain: string): void => {
    this.setState((s) => ({ ...s, loading: true }));
    middleWay.getAgencyInfo(domain).then((agency) => {
      const availableLanguages = (agency.languages?.filter(l => l !== 'eng' && l in fromCode)?.length ? agency.languages : null);
      this.setState({
        step: availableLanguages ? 'languageSelection' : 'agencyWelcomeNote',
        agencyUuid: agency.agencyUuid,
        agencyName: agency.agencyName,
        layout: agency.layout,
        activePages: agency.activePages,
        loading: false,
        pages: agency.pages,
        availableLanguages,
      });
    });
  };

  private readonly matchProfileToState = async (profileParams: IProfileParams) => {
    const { pages } = this.state;
    const pageKeyMap = [
      { pageKey: 'additionalInformation', profileKey: 'additionalInformation' },
      { pageKey: 'shiftClientPreferences', profileKey: 'shiftPreferences' },
      { pageKey: 'scheduleWorkPreferences', profileKey: 'workPreferences' },
    ];
    this.setState({ loading: true });
    const profile: any = profileParams ? { ...profileParams } : {};
    const newPages = { ...pages };

    pageKeyMap.forEach((item) => {
      const { pageKey, profileKey } = item;
      const keys = Object.keys(profile[profileKey] || {});
      const updated = { ...pages[pageKey] };
      keys.forEach((i) => {
        const fieldIndex = updated.fields.findIndex((f) => f.id === i);
        if (fieldIndex >= 0) {
          updated.fields[fieldIndex].value = profile[profileKey][i];
        }
      });
      newPages[pageKey] = updated;
    });

    this.setState({ pages: newPages });
  };

  public sendVerification = async (verificationNumber: string) => {
    const { activePages } = this.state;
    this.setState({ loading: true });
    const profile = await backend.sendVerification(verificationNumber);
    if (profile.profile.verificationStatus?.status === 'COMPLETED') {
      await this.matchProfileToState(profile.profile);
      const step = activePages[activePages.indexOf('basicInformation') + 1];
      this.setState({ step, loading: false });
    } else if (profile.profile.verificationStatus?.status === 'FAILED') {
      this.setState({
        verificationError: 'The code you entered is incorrect. Please try again.',
        loading: false,
      });
    } else if (profile.profile.verificationStatus?.status === 'EXPIRED') {
      this.setState({
        verificationError: 'The code you entered is expired. Resend code to receive a new one.',
        loading: false,
      });
    } else {
      this.setState({ loading: false });
    }
  };

  public resendVerification = async () => {
    this.setState({ loading: true });
    await backend.resendVerification();
    this.setState({ verificationError: '', loading: false });
  };

  private readonly checkForIEBrowser = () => {
    const ua = window.navigator.userAgent;
    const isIe = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    if (isIe) {
      this.setState((s) => ({ ...s, usingIE: true }));
    }
  };

  private readonly setLanguage = (language: string, initial: boolean = false) => {
    this.setState(s => ({ ...s, language, ...(initial && { step: s.activePages[0] }) }));
  }

  public componentDidMount(): void {
    this.checkForIEBrowser();
    if (!this.state.agencyUuid?.length) {
      let domine = window.location.origin;
      if (domine.substring(0, 5) === 'https') {
        domine = domine.replace('https://', '');
      }
      if (domine.includes('localhost') || domine.includes('10.0.2.2')) {
        domine = 'at-dev.gothamcare.com';
      }
      this.startSession(domine);
    }
  }

  public componentDidUpdate(_prevProps: Readonly<{}>, prevState: Readonly<IAppState>): void {
    const { step } = this.state;
    if (step !== prevState.step) {
      this.validateRequired();
    }
  }

  public render(): JSX.Element {
    const {
      step,
      layout,
      pages,
      valid,
      agencyName,
      loading,
      verificationError,
      preview,
      errorMessage,
      usingIE,
      language,
      availableLanguages,
    } = this.state;
    const email = pages.basicInformation?.fields.length
      ? pages.basicInformation?.fields?.find((f) => f.id === 'email')
      : undefined;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
    };

    const page = preview || step;

    return (
      <LanguageContext.Provider value={language}>
        <LocalizeProvider>
          <div className='App'>
            {usingIE && (
              <div id='loading-container'>
                <Image src='/BrowserSelection.png' inline={true} centered={true} />
              </div>
            )}
            {loading && (
              <div id='loading-container'>
                <Lottie options={defaultOptions} height={100} width={100} />
              </div>
            )}

            {page === 'languageSelection' && !loading && !usingIE && availableLanguages && (
              <LanguageSelection
                layout={layout}
                languages={availableLanguages.filter(l => l in fromCode).map(l => fromCode[l])}
                onChooseLanguage={this.setLanguage}
              />
            )}

            {page === 'agencyWelcomeNote' && !loading && !usingIE && (
              <WelcomePage
                agencyWelcome={pages.agencyWelcomeNote}
                layout={layout}
                agencyName={agencyName}
                onGetStarted={this.getStarted}
                onValueChange={this.onValueChange}
                valid={valid}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'verificationPage' && !loading && (
              <VerificationPage
                email={email?.value}
                sendVerification={this.sendVerification}
                resendVerification={this.resendVerification}
                layout={layout}
                error={verificationError}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'basicInformation' && !loading && (
              <BasicInfo
                basicInformation={pages.basicInformation}
                layout={layout}
                onValueChange={this.onValueChange}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'additionalInformation' && !loading && (
              <AdditionalInformation
                additionalInformation={pages.additionalInformation}
                layout={layout}
                onValueChange={this.onValueChange}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'workExperience' && !loading && (
              <WorkExperience
                workExperience={pages.workExperience}
                layout={layout}
                onValueChange={this.onValueChange}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'backgroundInformation' && !loading && (
              <BackgroundInfo
                backgroundInformation={pages.backgroundInformation}
                layout={layout}
                onValueChange={this.onValueChange}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'scheduleWorkPreferences' && !loading && (
              <ScheduleWorkPreferences
                scheduleWorkPreferences={pages.scheduleWorkPreferences}
                layout={layout}
                onValueChange={this.onValueChange}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'shiftClientPreferences' && !loading && (
              <ShiftClientPreferences
                shiftClientPreferences={pages.shiftClientPreferences}
                layout={layout}
                onValueChange={this.onValueChange}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
                valid={valid}
                errorMessage={errorMessage}
                setLanguage={this.setLanguage}
                languages={availableLanguages}
              />
            )}

            {page === 'completionPage' && !loading && (
              <FinishPage
                layout={layout}
                agencyName={agencyName}
                completion={pages.completionPage}
                onCancel={this.prevPage}
                onSubmit={this.nextPage}
              />
            )}
          </div>
        </LocalizeProvider>
      </LanguageContext.Provider>
    );
  }
}

export default App;
