import React from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ReactComponent as PoweredBy } from '../images/PoweredBy.svg';
import { FormPageProps } from '../types/props';
import './FormPage.scss';
import { useLanguage } from '../context/language';
import { fromCode } from '../constants/languages';
import { useLocalize } from '../context/localize';

type FormPageInternalProps = {
  valid?: boolean;
  noButtons?: boolean;
  children?: React.ReactNode;
  enablePrev?: boolean;
  errorMessage?: string;
} & FormPageProps;

const ErrorMessage = styled.p`
  text-align: center;
  margin-top: 3rem;
  font-size: 14px;
  color: #f16778;
`;

const LanguageFlagContainer = styled.div`
  width: 1.5rem;
  display: inline-block;
  margin-right: 0.5rem;

  > img {
    width: 1.5rem;
  }
`;

function FormPage(props: FormPageInternalProps): JSX.Element {
  const language = useLanguage();
  const localize = useLocalize();

  const BgImage =
    props.layout?.headerImage === 'http://www.example.com/image.jpg'
      ? 'url(/ApplicationHeader.png)'
      : `url(${props.layout?.headerImage.replace(/\s+/g, "_")})`;
  const logoImage =
    props.layout?.logoImage === 'http://www.example.com/image.jpg'
      ? '/AplicationAgencyLogo.jpg'
      : props.layout?.logoImage.replace(/\s+/g, "_");

  const { setLanguage, languages } = props;

  return (
    <div className='form-page' style={{ backgroundColor: `${props.layout?.backgroundColor}` }}>
      <div
        className='form-header'
        style={{
          backgroundImage: BgImage,
          backgroundSize: 'cover',
        }}
      >
        {props.layout && <img className='form-header-logo' src={logoImage} alt='' />}
      </div>
      {setLanguage && languages && (
        <div className='form-language-container'>
          <Dropdown
            className='form-language-dropdown'
            selection={true}
            value={language || ''}
            options={languages.filter(l => l in fromCode).map(l => fromCode[l]).map(({ code, name, flagCountryCode }) => ({
              text: <><LanguageFlagContainer><img src={`/flags/${flagCountryCode}.png`} alt={name} /></LanguageFlagContainer>{name}</>,
              value: code,
            }))}
            onChange={(_: any, d: any) => {
              setLanguage(d.value);
            }}
            style={{ position: 'absolute' }}
          />
        </div>
      )}
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
      <div className='form-body'>{props.children}</div>
      <div className='form-footer-container'>
        <div className='form-footer'>
          <div className={props.noButtons ? 'form-footer-logo-no-buttons' : 'form-footer-logo'}>
            <PoweredBy />
          </div>
          {!props.noButtons && (
            <>
              {props.enablePrev && (
                <Button id='form-cancel' icon={true} labelPosition='left' onClick={props.onCancel}>
                  <Icon name='chevron left' />
                  {localize('formPagePrev')}
                </Button>
              )}
              <Button id='form-submit' icon={true} labelPosition='right' disabled={!props.valid} onClick={props.onSubmit}>
                {localize('formPageNext')}
                <Icon name='chevron right' />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormPage;
