import config from './config';
import { mapWelcomePageFields } from './helpers/mapWelcomePageFields';
import { IFields, IVerificationStatus } from './types/state';

let sessionToken: string;

export async function errorFetch(url: string, init?: RequestInit): Promise<Response> {
  return new Promise((resolve, reject) => {
    fetch(url, init)
      .then((res) => {
        if (res.ok) {
          resolve(res);
        } else {
          res
            .json()
            .then((c) => reject(c))
            .catch((e) => console.log(e));
        }
      })
      .catch((err) => reject(err));
  });
}

function createHeaders(): Headers | string[][] | Record<string, string> | undefined {
  const authHeaders = { Authorization: `Bearer ${sessionToken}` };
  return { ...authHeaders };
}

function setToken(token: string): void {
  sessionToken = token;
}

export interface ILayout {
  backgroundColor: string;
  fontSize: string;
  headerImage: string;
  logoImage: string;
}

export interface IPages {
  [key: string]: {
    order: number;
    active: boolean;
    fields: IFields[];
  };
}

export interface IAgencyInfo {
  agencyName: string;
  layout: ILayout;
  agencyUuid: string;
  pages: IPages;
  name: string;
  logoUrl: string;
  languages?: string[];
}

async function getAgencyInfo(domain: string): Promise<IAgencyInfo> {
  const url = `${config.API}/session`;
  return errorFetch(url, {
    method: 'POST',
    body: JSON.stringify({ domain }),
  })
    .then((res) => res.json())
    .then((data) => {
      setToken(data.token);
      return data;
    });
}

export interface IFieldsValue {
  [key: string]: any;
}

export interface IProfileParams {
  token: string;
  agencyUuid?: string;
  welcome: IFieldsValue;
  basicInformation?: IFieldsValue;
  additionalInformation?: IFieldsValue;
  workExperience?: IFieldsValue;
  backgroundInformation?: IFieldsValue;
  workPreferences?: IFieldsValue;
  shiftPreferences?: IFieldsValue;
  verificationStatus?: IVerificationStatus;
  campaignToken: string | null;
  pages: IPages;
}

export interface IProfileResponse {
  profile: IProfileParams;
}

export interface IWelcome {
  disciplines: string[];
}

export interface IUpdateProfileResponse {
  verificationStatus: IVerificationStatus;
  welcome: IWelcome;
}

async function updateProfile(
  params: IProfileParams,
  createProfile: boolean,
  complete: boolean
): Promise<IUpdateProfileResponse> {
  const url = `${config.API}/profile/${sessionToken}?completed=${complete}`;
  return errorFetch(url, {
    method: createProfile ? 'POST' : 'PUT',
    body: JSON.stringify({
      token: sessionToken,
      welcome: mapWelcomePageFields(params.welcome),
      agencyUuid: params.agencyUuid,
      basicInformation: params.basicInformation,
      additionalInformation: params.additionalInformation,
      workExperience: params.workExperience,
      backgroundInformation: params.backgroundInformation,
      workPreferences: params.workPreferences,
      shiftPreferences: params.shiftPreferences,
      campaignToken: params.campaignToken,
      pages: { ...params.pages, agencyWelcomeNote: undefined, completionPage: undefined },
    }),
    headers: createHeaders(),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
}

interface IUploadImageResponse {
  url: string;
}

async function uploadIdImage(contentType: string): Promise<IUploadImageResponse> {
  const url = `${config.API}/uploadurl`;

  return errorFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      token: sessionToken,
      contentType,
    }),
    headers: createHeaders(),
  }).then((res) => {
    return res.json();
  });
}

async function sendVerification(code: string): Promise<IProfileResponse> {
  const url = `${config.API}/verification`;

  return errorFetch(url, {
    method: 'POST',
    body: JSON.stringify({
      token: sessionToken,
      verificationCode: code,
    }),
    headers: createHeaders(),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
}

async function resendVerification(): Promise<void> {
  const url = `${config.API}/verification`;

  return errorFetch(url, {
    method: 'PUT',
    body: JSON.stringify({ token: sessionToken }),
    headers: createHeaders(),
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    });
}

async function finishApplication(fields: {}): Promise<void> {
  const url = `${config.API}/finish`;
  return errorFetch(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(fields),
  }).then(() => undefined);
}

function documentUpload(contentType: string, fieldId?: string) {
  const url = `${config.API}/documentuploadurl`;

  return errorFetch(url, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify({
      token: sessionToken,
      contentType,
      fieldId,
    }),
  });
}

const backend = {
  getAgencyInfo,
  uploadIdImage,
  finishApplication,
  updateProfile,
  sendVerification,
  resendVerification,
  documentUpload,
};

export default backend;
