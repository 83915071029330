import { DropdownItemProps } from 'semantic-ui-react';

export const monthOptions: DropdownItemProps[] = [
  {
    text: 'January',
    value: 1,
  },
  {
    text: 'February',
    value: 2,
  },
  {
    text: 'March',
    value: 3,
  },
  {
    text: 'April',
    value: 4,
  },
  {
    text: 'May',
    value: 5,
  },
  {
    text: 'June',
    value: 6,
  },
  {
    text: 'July',
    value: 7,
  },
  {
    text: 'August',
    value: 8,
  },
  {
    text: 'September',
    value: 9,
  },
  {
    text: 'October',
    value: 10,
  },
  {
    text: 'November',
    value: 11,
  },
  {
    text: 'December',
    value: 12,
  },
];

export const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
