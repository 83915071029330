import backEnd, { IPages } from '../backend';
import { Steps } from '../types/state';

type Layout = {
  backgroundColor: string;
  fontSize: string;
  headerImage: string;
  logoImage: string;
};

interface IAgencyInfo {
  agencyUuid: string;
  agencyName: string;
  layout: Layout;
  pages: IPages;
  activePages: Steps[];
  languages?: string[];
}

async function getAgencyInfo(domain: string): Promise<IAgencyInfo> {
  const agencyInfo = await backEnd.getAgencyInfo(domain).then((info) => info);
  const pages = agencyInfo.pages;
  const activePages = Object.entries(pages)
    .map(([pageKey, val]) => ({ pageKey, ...val }))
    .filter((p) => p.active)
    .sort((a, b) => a.order - b.order)
    .map((p) => p.pageKey) as Steps[];

  const agencySettings: IAgencyInfo = {
    agencyUuid: agencyInfo.agencyUuid,
    agencyName: agencyInfo.agencyName,
    layout: agencyInfo.layout,
    pages,
    activePages,
    languages: agencyInfo.languages,
  };
  return agencySettings;
}

async function uploadIdDocument(IdImage: any): Promise<void> {
  const uploadFile = (uploadLink: string) =>
    new Promise((resolve, reject) => {
      const upload = (e: any) => {
        const path = uploadLink;
        fetch(path, {
          method: 'PUT',
          body: e.currentTarget.result,
          headers: {
            'content-length': IdImage?.size,
            'Content-Type': IdImage?.type,
          },
        })
          .then((s) => {
            if (s.status >= 400) {
              const error = new Error(`HTTP Error ${s.statusText}`);
              throw error;
            } else {
              resolve();
            }
            return {};
          })
          .catch((error) => reject(error));
      };

      const reader = new FileReader();
      reader.onload = upload;
      reader.readAsArrayBuffer(IdImage);
    });

  backEnd
    .uploadIdImage(IdImage?.type)
    .then((results: { url: string }) => uploadFile(results.url))
    .catch((error: any) => {
      console.log(error);
    });
}

const middleWay = {
  getAgencyInfo,
  uploadIdDocument,
};

export default middleWay;
