import React, { useState, useEffect } from 'react';
import { ILayout } from '../backend';
import './LanguageSelection.scss';
import LanguageButton from './subcomponents/LanguageButton';
import LanguageCard from './subcomponents/LanguageCard';
import FormPage from './FormPage';
import { Language } from '../constants/languages';

interface IProps {
  layout?: ILayout;
  languages: Language[];
  onChooseLanguage: (language: string, initial: boolean) => void;
}

function LanguageSelection({
  layout,
  languages,
  onChooseLanguage,
}: IProps): JSX.Element {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const languageButtons = languages.map(l => <LanguageButton language={l} onClick={() => onChooseLanguage(l.code, true)} key={l.code} />)

  const languageCards = languages.map(l => <LanguageCard language={l} onClick={() => onChooseLanguage(l.code, true)} key={l.code} />)

  return (
    <FormPage layout={layout} noButtons={true}>
      <h1>Hello!</h1>
      <p>Please choose your preferred language</p>
      <div className={windowWidth >= 800 ? 'language-selection-container-grid' : ''}>
        {windowWidth >= 800 ? languageCards : languageButtons}
      </div>
    </FormPage>
  );
}

export default LanguageSelection;
