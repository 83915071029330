import React, { ChangeEvent, DragEvent, Fragment, MouseEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import inbox from '../../images/inbox.svg';
import attachment from '../../images/attachment.svg';
import { DocumentInfo, IFields } from '../../types/state';
import { useTranslation } from '../../context/language';
import { useLocalize } from '../../context/localize';

interface ContainerProps {
  itemInDropZone: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #4a4a4a;

  background: ${({ itemInDropZone }) => (itemInDropZone ? '#EEF2FE' : '#fff')};
  border: ${({ itemInDropZone }) => (itemInDropZone ? '1px solid #1CA6D3' : 'none')};

  & * {
    pointer-events: ${({ itemInDropZone }) => itemInDropZone && 'none'};
  }
`;

const RemoveButton = styled.button`
  background-color: #fff;
  border: 1px solid #f16778;
  border-radius: 4px;
  color: #f16778;
  font-family: Lato, sans-serif;
  font-size: 11px;
  line-height: 16px;
  height: 20px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
`;

const validExtensions = ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'gif', 'png'];
const megabyte = 1048576;
const fileSizeLimit = 5 * megabyte;

const getStep = (step?: string) => {
  const steps = {
    basicInformation: 'Basic Information',
    additionalInformation: 'Additional Information',
    workExperience: 'Work Experience',
    backgroundInformation: 'Background Information',
    scheduleWorkPreferences: 'Work Preferences',
    shiftClientPreferences: 'Client Preferences',
  };

  return steps[step as keyof typeof steps];
};

const isValidFile = (fileList: FileList) => {
  let valid = true;

  Object.keys(fileList).forEach((key) => {
    const file = fileList[key as keyof typeof fileList] as File;
    const { name, size } = file;
    const extension = name.split('.').pop() || '';

    if (!validExtensions.includes(extension) || size > fileSizeLimit) {
      valid = false;
    }
  });

  return valid;
};

const fileListToArray = (fileList: FileList, blockId?: number, label?: string, step?: string) => {
  return Object.keys(fileList).map((key) => {
    const file = fileList[key as keyof typeof fileList] as File;
    const url = URL.createObjectURL(file);
    return { file, url, blockId, appFieldName: label, appSection: getStep(step) };
  });
};

interface DocumentUploaderProps {
  field: IFields;
  step: string;
  onValueChange?(field: string, value: any, step: string, valid?: boolean): void;
}

export function DocumentUploader({ field, onValueChange, step }: DocumentUploaderProps) {
  const translation = useTranslation(field.translations);
  const localize = useLocalize();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [itemInDropZone, setItemInDropZone] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const { blockId, label: fieldLabel, value } = field;
  const files = (value as DocumentInfo[]) || [];

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length && isValidFile(e.target.files)) {
      const fileList = fileListToArray(e.target.files, blockId, fieldLabel, step);

      setInvalidFile(false);

      if (onValueChange) onValueChange(field.id, [...files, ...fileList], step, true);
    } else if (e.target.files?.length && !isValidFile(e.target.files)) {
      setInvalidFile(true);
    }

    e.target.value = '';
  };

  const handleOnClick = (e: MouseEvent) => {
    e.preventDefault();
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setItemInDropZone(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setItemInDropZone(false);
  };

  const handleDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const { dataTransfer } = e;

    if (isValidFile(dataTransfer.files)) {
      const fileList = fileListToArray(dataTransfer.files, blockId, fieldLabel, step);
      if (onValueChange) onValueChange(field.id, [...files, ...fileList], step, true);
      setInvalidFile(false);
    } else {
      setInvalidFile(true);
    }

    setItemInDropZone(false);
  };

  const handleRemove = (index: number) => {
    const newFileArray = [...files];
    newFileArray.splice(index, 1);
    if (onValueChange) onValueChange(field.id, newFileArray, step, !!newFileArray.length);
  };

  const label = translation?.label || field.label;

  return (
    <div className='document-uploader' style={{ width: '100%', marginTop: '20px', marginBottom: '20px', paddingLeft: '10px', paddingRight:'10px' }}>
      <h2
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontFamily: 'Lato, sans-serif',
          color: '#4a4a4a',
          marginBottom: '6px',
          marginLeft: '5px',
        }}
      >
        {label}
        {field.required && (
          <span style={{ color: '#f16778', fontSize: '12px', marginLeft: '4px' }}>*</span>
        )}
      </h2>
      <Container
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={handleDragLeave}
        itemInDropZone={itemInDropZone}
      >
        <h3 style={{ display: 'flex', justifyContent: 'center', fontSize: '14px' }}>
          {localize('documentUploadHeader')}
        </h3>

        <img src={inbox} alt='upload' style={{ margin: '10px 0px 20px' }} />

        {files && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '150px 50px',
              justifyContent: 'center',
              fontSize: '12px',
            }}
          >
            {files.map((fileData, index) => {
              const { file, url } = fileData;
              const { name } = file;

              return (
                <Fragment key={url}>
                  <div style={{ marginBottom: '10px', marginRight: '10px' }}>
                    <a
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ display: 'flex', color: '#4a4a4a' }}
                    >
                      <img src={attachment} alt='attachment' />
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {name}
                      </div>
                    </a>
                  </div>
                  <RemoveButton onClick={() => handleRemove(index)}>{localize('documentRemove')}</RemoveButton>
                </Fragment>
              );
            })}
          </div>
        )}

        <p>
          <span>{localize('documentChooseFile1')}{' '}</span>
          <a href='/' onClick={handleOnClick} style={{ color: '#1ca6d3' }}>
            {localize('documentChooseFile2')}
          </a>
          <span>{' '}{localize('documentChooseFile3')}</span>
        </p>

        {!invalidFile ? (
          <p style={{ fontSize: '12px' }}>{localize('documentFormat')}</p>
        ) : (
          <p style={{ color: '#f16778', fontSize: '12px', marginTop: '20px' }}>
            {localize('documentError')}
          </p>
        )}

        <input
          onChange={handleOnInputChange}
          ref={fileUploadRef}
          type='file'
          multiple={true}
          accept='.doc,.docx,.pdf,.jpg,.jpeg,.gif,.png'
          style={{ display: 'none' }}
        />
      </Container>
    </div>
  );
}
