import React, { Component } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { IContainerProps, IFields, Steps } from '../../types/state';
import Label from './Label';
import { LanguageContext, selectTranslation } from '../../context/language';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: string[], step: Steps, valid?: boolean) => void;
  step: Steps;
  value?: string[];
}
interface IState {
  [ket: string]: boolean | string[] | undefined;
  checkedList?: string[];
}

interface ICheckBoxProps {
  checked?: boolean | string[];
}

const MainFieldContainer = styled.div`
  width: 100%;
  padding: 1rem;
  grid-column: ${(props: IContainerProps) =>
    props.step === 'basicInformation' || props.step === 'additionalInformation'
      ? 'span 1'
      : 'span 1'};
  @media (max-width: 500px) {
    grid-column: 1;
  }
`;

const MainCheckContainer: StyledComponent<'div', any, IContainerProps, never> &
  ICheckBoxProps = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-template-rows: auto;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
  margin: 0.5rem 0;
  width: 100%;
`;

const CheckBoxContainer: StyledComponent<'div', any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CheckBox: StyledComponent<'div', any, ICheckBoxProps, never> & ICheckBoxProps = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 6px;
  margin-right: 1rem;
  border: ${(props: ICheckBoxProps) => (props.checked ? 'none' : '1px solid #c8cbd3')};
  outline: none;
  background-color: ${(props: ICheckBoxProps) => (props.checked ? '#69DB8F' : '')};
  background-image: ${(props: ICheckBoxProps) => (props.checked ? 'url(/Check.svg)' : '')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
`;

const LabelText = styled.p`
  color: #9b9bb9;
  text-align: start;
  font-size: 12px;
`;

class MultiCheckboxField extends Component<IProps, IState> {
  public state: IState = {
    checkedList: [],
  };

  public fileInput: { [key: string]: HTMLInputElement | null } = {};

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { value } = this.props;
    this.setState((s) => ({ ...s, checkedList: value || [] }));
  };

  private readonly onCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { field, step, onValueChange = () => null } = this.props;
    const { checkedList } = this.state;
    const target = e.target?.id;

    if (checkedList?.includes(target)) {
      const newFilteredCheckedList = checkedList?.filter((c) => !(c === target));
      this.setState((s) => ({ ...s, checkedList: newFilteredCheckedList }));
      return onValueChange(field.id, newFilteredCheckedList, step, !!newFilteredCheckedList.length);
    }
    const newCheckedList = checkedList;
    newCheckedList?.push(target);
    this.setState((s) => ({ ...s, checkedList: newCheckedList }));
    return onValueChange(field.id, newCheckedList || [''], step, !!newCheckedList?.length);
  };

  private readonly handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const field = e.currentTarget.id;
    this.fileInput[field]?.click();
  };

  public render(): JSX.Element {
    const { field, step } = this.props;
    return (
      <LanguageContext.Consumer>
        {language => {
          const translation = selectTranslation(field.translations, language);

          return (
            <MainFieldContainer step={step}>
              <Label field={field} />
              <MainCheckContainer>
                {field.options?.length &&
                  field.options?.map((o: string, i: number) => (
                    <CheckBoxContainer key={`${o}-${i}`}>
                      <CheckBox
                        id={`${o}`}
                        onClick={this.handleClick}
                        checked={this.state.checkedList?.includes(o)}
                      >
                        <input
                          ref={(r) => (this.fileInput[o] = r)}
                          id={`${o}`}
                          onChange={this.onCheckBoxChange}
                          type='checkbox'
                          style={{ display: 'none' }}
                        />
                      </CheckBox>
                      <LabelText>{translation?.options?.[i] || o}</LabelText>
                    </CheckBoxContainer>
                  ))}
              </MainCheckContainer>
            </MainFieldContainer>
          );
        }}
      </LanguageContext.Consumer>
    );
  }
}

export default MultiCheckboxField;
