import React, { Component } from 'react';
import styled from 'styled-components';
import { ILayout } from '../backend';
import { IFields, IPageFields, Steps } from '../types/state';
import FormPage from './FormPage';
import SubComponents from './subcomponents/SubComponents';
import './WelcomePage.scss';
import { LocalizeContext } from '../context/localize';

interface IProps {
  layout?: ILayout;
  agencyName: string;
  agencyWelcome: IPageFields;
  onGetStarted: () => void;
  onValueChange: (field: string, value: any, step: Steps, valid?: boolean) => void;
  setLanguage: (language: string) => void;
  languages: string[] | null;
  valid: boolean;
}

const GrayCircle = styled.div`
  justify-self: center;
  background-color: #c8cbd3;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 1rem;
  display: inline-flex;
`;

class WelcomePage extends Component<IProps> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    isStarted: false
  };

  public getStarted = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const { onGetStarted } = this.props;
    e.preventDefault();
    if (!this.state.isStarted) {
      this.setState( { isStarted: true });
      onGetStarted();
    }
  };

  public render(): JSX.Element {
    const { agencyWelcome, layout, agencyName, onValueChange, valid, setLanguage, languages } = this.props;
    const requiredFields = agencyWelcome.fields?.filter((f: IFields) => f.required);
    const localize = this.context;

    return (
      <FormPage layout={layout} valid={valid} noButtons={true} setLanguage={setLanguage} languages={languages}>
        <div className='welcome-note-container'>
          <p className='form-title'>{localize('welcomeTitle')}</p>
          <h3 className='form-header'>{agencyName}</h3>
          <GrayCircle />
          {!!agencyWelcome.fields?.length &&
            agencyWelcome.fields
              .filter((f: IFields) => f.visible)
              .map((f: IFields) => <SubComponents key={f.id} field={f} step='agencyWelcomeNote' onValueChange={onValueChange} />)}
          <button className='get-started-button' disabled={(!valid && !!requiredFields.length)} onClick={this.getStarted}>
            {localize('welcomeGetStarted')}
          </button>
        </div>
      </FormPage>
    );
  }
}

export default WelcomePage;
