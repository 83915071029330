import { createContext, useContext } from "react";

export const LanguageContext = createContext<string | null>(null);

export const selectTranslation = (translations: Record<string, any> | undefined, language: string | null): any | null => {
  if (language && translations && translations[language]) return translations[language];
  return null;
};

export const useLanguage = () => useContext(LanguageContext);
export const useTranslation = (translations: Record<string, any> | undefined): any | null => {
  const language = useLanguage();
  return selectTranslation(translations, language);
};
