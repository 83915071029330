import React, { Component } from 'react';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import '../ApplicantPage.scss';
import Label from './Label';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: string, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: string;
}
interface IState {
  selected?: string;
}

const GenderContainer = styled.div`
  text-align: start;
  display: grid;
  // ! columns could change to 4 if we implement rather not say gender on CC web
  grid-template-columns: repeat(3, 1fr);
  padding: 1rem;
  width: 90%;
  grid-column: span 1;
`;

class GenderComponent extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = {
    selected: undefined,
  };

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { field, step, value, onValueChange = () => null } = this.props;
    onValueChange(field.id, value || '', step, !!value);
    this.setState((s) => ({ ...s, selected: value }));
  };

  private readonly handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { field, step, onValueChange = () => null } = this.props;
    const id = e.currentTarget.id;
    let newGender: string | undefined = '';
    switch (id) {
      case 'male':
        newGender = 'm';
        this.setState((s) => ({ ...s, selected: newGender }));
        break;
      case 'female':
        newGender = 'f';
        this.setState((s) => ({ ...s, selected: newGender }));
        break;
      case 'other':
        newGender = 'u';
        this.setState((s) => ({ ...s, selected: newGender }));
        break;
      case 'not-say':
        newGender = 'n';
        this.setState((s) => ({ ...s, selected: newGender }));
        break;
      default:
        newGender = undefined;
        this.setState((s) => ({ ...s, selected: newGender }));
    }
    return onValueChange(field.id, newGender || '', step, !!newGender);
  };

  public render(): JSX.Element {
    const { selected } = this.state;
    const localize = this.context;

    return (
      <GenderContainer className='form-card applicant-gender'>
        <Label
          field={{ label: localize('genderSelect'), required: false }}
          style={{ gridColumn: '1 / span 4', placeSelf: 'center' }}
        />
        <div
          className={`applicant-gender-item ${selected === 'm' ? 'selected' : ''}`}
          id='male'
          onClick={this.handleClick}
        >
          {selected === 'm' && (
            <div
              style={{ backgroundImage: 'url(/Check.svg)' }}
              className='applicant-gender-check'
            />
          )}
          <div
            style={{ backgroundImage: 'url(/Male.svg)' }}
            className={`applicant-gender-icon ${selected === 'm' ? 'selected' : ''}`}
          />
          <p className={`applicant-gender-label ${selected === 'm' ? 'selected' : ''}`}>{localize('genderMale')}</p>
        </div>
        <div
          className={`applicant-gender-item ${selected === 'f' ? 'selected' : ''}`}
          id='female'
          onClick={this.handleClick}
        >
          {selected === 'f' && (
            <div
              style={{ backgroundImage: 'url(/Check.svg)' }}
              className='applicant-gender-check'
            />
          )}
          <div
            style={{ backgroundImage: 'url(/Female.svg)' }}
            className={`applicant-gender-icon ${selected === 'f' ? 'selected' : ''}`}
          />
          <p className={`applicant-gender-label ${selected === 'f' ? 'selected' : ''}`}>{localize('genderFemale')}</p>
        </div>
        <div
          className={`applicant-gender-item ${selected === 'u' ? 'selected' : ''}`}
          id='other'
          onClick={this.handleClick}
        >
          {selected === 'u' && (
            <div
              style={{ backgroundImage: 'url(/Check.svg)' }}
              className='applicant-gender-check'
            />
          )}
          <div
            style={{ backgroundImage: 'url(/Other.svg)' }}
            className={`applicant-gender-icon ${selected === 'u' ? 'selected' : ''}`}
          />
          <p className={`applicant-gender-label ${selected === 'u' ? 'selected' : ''}`}>{localize('genderOther')}</p>
        </div>
        {/* // ! rather not say gender is not yet supported on CC web
        <div
          className={`applicant-gender-item ${selected === 'n' ? 'selected' : ''}`}
          id='not-say'
          onClick={this.handleClick}
        >
          {selected === 'n' && (
            <div
              style={{ backgroundImage: 'url(/Check.svg)' }}
              className='applicant-gender-check'
            />
          )}
          <div
            style={{ backgroundImage: 'url(/Missing.svg)' }}
            className={`applicant-gender-icon ${selected === 'n' ? 'selected' : ''}`}
          />
          <p className={`applicant-gender-label ${selected === 'n' ? 'selected' : ''}`}>
            Rather not say
          </p>
        </div> */}
      </GenderContainer>
    );
  }
}

export default GenderComponent;
