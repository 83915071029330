import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import styled, { StyledComponent } from 'styled-components';
import { IFields, IInputValidation, Steps } from '../../types/state';
import Label from './Label';
import { LocalizeContext } from '../../context/localize';

const SSN_REGEX = /^\d{3}-\d{2}-\d{4}$/;

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: any, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: string;
}
interface IState {
  fullSSN?: string;
  start: string;
  middle: string;
  end: string;
  isValid: boolean;
}

const SSNContainer = styled.div`
  display: grid;
  place-self: flex-start;
  grid-column: span 1;
  grid-row: span 1;
  place-items: center;
  grid-template-columns: 3fr 1ch 2fr 1ch 4fr;
  column-gap: 1ch;
  padding: 1rem;
`;

const SSNInput: StyledComponent<'input', any, IInputValidation, never> &
  IInputValidation = styled.input`
  border: none;
  height: 50px;
  background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  padding-left: 10px;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    font-weight: bold;
    font-family: Lato, sans-serif;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    border: none;
    background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  }
`;

const SeparateLine = styled.div`
  display: inline-block;
  align-self: center;
  width: 1ch;
  height: 1.5px;
  background-color: #4a4a4a;
`;

const LinkText = styled.p`
  font-size: 14px;
  color: #04a5d5;
  text-align: right;
  grid-column: 4 / span 3;
  grid-row: 1;
  margin: 1rem;
  padding-right: 1rem;
  width: 100%;
`;

class SsnComponent extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public state = { fullSSN: '', start: '', middle: '', end: '', isValid: false };

  public startRef?: HTMLInputElement | null;
  public middleRef?: HTMLInputElement | null;
  public endRef?: HTMLInputElement | null;

  public componentDidMount(): void {
    this.setValuesToState();
  }

  private readonly setValuesToState = () => {
    const { value } = this.props;
    const valid = SSN_REGEX.test(value || '');
    if (value?.length) {
      const start = value.substring(0, 3);
      const middle = value.substring(4, 6);
      const end = value.substring(7);
      this.setState((s) => ({ ...s, fullSSN: value, start, middle, end, isValid: valid }));
    }
  };

  private readonly onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { field, step, onValueChange = () => null } = this.props;
    const { fullSSN, start, middle, end } = this.state;
    const value = e.target.value;
    const id = e.target.id;
    let newFullSSN = fullSSN;
    switch (id) {
      case 'start-ssn':
        newFullSSN = `${value}-${middle}-${end}`;
        if (value.length === 3) {
          this.middleRef?.focus();
        }
        this.setState((s) => ({ ...s, start: value, fullSSN: newFullSSN }));
        break;
      case 'middle-ssn':
        newFullSSN = `${start}-${value}-${end}`;
        if (value.length === 2) {
          this.endRef?.focus();
        }
        this.setState((s) => ({ ...s, middle: value, fullSSN: newFullSSN }));
        break;
      case 'end-ssn':
        newFullSSN = `${start}-${middle}-${value}`;
        this.setState((s) => ({ ...s, end: value, fullSSN: newFullSSN }));
        break;
    }

    const valid = SSN_REGEX.test(newFullSSN);
    this.setState((s) => ({ ...s, fullSSN: value, isValid: valid }));
    return onValueChange(field.id, newFullSSN, step, valid);
  };

  public render(): JSX.Element {
    const { field } = this.props;
    const { fullSSN, start, middle, end, isValid } = this.state;
    const localize = this.context;

    return (
      <SSNContainer>
        <Label
          field={field}
          style={{ gridColumn: '1 / span 3', justifySelf: 'flex-start', paddingTop: '12px' }}
        />
        <Popup
          style={{ gridColumn: '4 / span 2', justifySelf: 'flex-start' }}
          position='top center'
          content={localize('ssnExplanation')}
          trigger={<LinkText>{localize('ssnWhy')}</LinkText>}
        />
        <SSNInput
          id='start-ssn'
          maxLength={3}
          ref={(r) => (this.startRef = r)}
          placeholder='***'
          type='password'
          autoComplete='off'
          valid={fullSSN ? isValid : true}
          value={start}
          onChange={this.onChange}
        />
        <SeparateLine />
        <SSNInput
          id='middle-ssn'
          maxLength={2}
          ref={(r) => (this.middleRef = r)}
          placeholder='**'
          type='password'
          autoComplete='off'
          valid={fullSSN ? isValid : true}
          value={middle}
          onChange={this.onChange}
        />
        <SeparateLine />
        <SSNInput
          id='end-ssn'
          maxLength={4}
          ref={(r) => (this.endRef = r)}
          placeholder='****'
          type='password'
          autoComplete='off'
          valid={fullSSN ? isValid : true}
          value={end}
          onChange={this.onChange}
        />
      </SSNContainer>
    );
  }
}

export default SsnComponent;
