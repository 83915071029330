import moment from 'moment';
import React, { Component, SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { monthOptions } from '../../constants/generic';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import { LocalizeContext } from '../../context/localize';

interface IProps {
  field: IFields;
  step: Steps;
  value?: string;
  onValueChange?: (field: string, value: any, step: Steps, valid: boolean, error: boolean) => void;
}
interface IState {
  fullDate: string;
  day?: number;
  month?: number;
  year?: number;
  dayCount: number;
  dateError: boolean;
}

const DATE_FORMAT = 'MM/DD/YYYY';

const DateContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  justify-self: flex-start;
  grid-template-columns: 5fr 3fr 4fr;
  grid-template-rows: repeat(2, auto);
  column-gap: 10px;
  grid-column: span 1;
  justify-items: left;
  padding: 1rem;
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: #f16778;
  text-align: center;
  margin: 0 1rem 1rem 1rem;
  grid-column: 1 / span 3;
`;

const DatePicker = styled(Dropdown)`
  &&&& {
    font-family: Lato, sans-serif;
    border: 1px solid #c8cbd3;
    max-height: 38px;
    width: 100%;
  }
  &&&& > i {
    border-left: 1px solid #c8cbd3;
    color: #c8cbd3;
  }
`;

class BirthDayComponent extends Component<IProps, IState> {
  public static contextType = LocalizeContext;
  public context!: React.ContextType<typeof LocalizeContext>;

  public yearOptions: DropdownItemProps[];
  public lastDayOptions: DropdownItemProps[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      fullDate: '',
      day: undefined,
      month: undefined,
      year: undefined,
      dayCount: 31,
      dateError: false,
    };

    this.yearOptions = this.getYearOptions(parseInt(moment().subtract(16, 'y').format('YYYY'), 10));
    this.lastDayOptions = this.getDayOptions();
  }

  public componentDidMount(): void {
    this.setPrevValueToState();
  }

  private readonly setPrevValueToState = () => {
    const { value } = this.props;
    if (value?.length) {
      const day = parseInt(moment(value).format('DD'), 10);
      const month = parseInt(moment(value).format('MM'), 10);
      const year = parseInt(moment(value).format('YYYY'), 10);
      this.setState((s) => ({ ...s, fullDate: value, day, month, year }));
    }
  };

  public getDayCount(): number {
    const { month, year } = this.state;

    // If the maximum day count can be determined, return it. Otherwise return 31.
    if (month === undefined || month < 1 || month > 12) return 31;

    // February: determine whether it is a leap year
    if (month === 2) {
      if (year !== undefined) {
        // https://en.wikipedia.org/wiki/Leap_year#Algorithm
        if (year % 4 !== 0) {
          return 28;
        } else if (year % 100 !== 0) {
          return 29;
        } else if (year % 400 === 0) {
          return 28;
        }
      }
      return 29;
    }

    // Other months: quick calculation
    if (month < 8) {
      // Before august
      return 30 + (month % 2);
    }

    // After august
    return 31 - (month % 2);
  }

  public getDayOptions(): DropdownItemProps[] {
    if (this.lastDayOptions.length === this.state.dayCount) return this.lastDayOptions;

    const options: DropdownItemProps[] = [];
    const { dayCount } = this.state;

    for (let i = 0; i < dayCount; i++) {
      options.push({
        text: i + 1,
        value: i + 1,
      });
    }

    return options;
  }

  public getYearOptions(until: number): DropdownItemProps[] {
    const options: DropdownItemProps[] = [];
    const from = parseInt(moment().subtract(90, 'y').format('YYYY'), 10);

    for (let i = from; i <= until; i++) {
      options.push({
        text: i,
        value: i,
      });
    }

    return options;
  }

  private readonly onDateChange = (_e: SyntheticEvent, d: DropdownProps) => {
    const { day, month, year } = this.state;
    const { field, step, onValueChange = () => null } = this.props;
    let newFullDate = '';
    switch (d.id) {
      case 'form-birth-day':
        newFullDate = moment(`${month}/${d.value}/${year}`).format(DATE_FORMAT);
        this.setState((s) => ({
          ...s,
          day: d.value as number,
          fullDate: newFullDate,
        }));
        break;
      case 'form-birth-month':
        newFullDate = moment(`${d.value}/${day}/${year}`).format(DATE_FORMAT);
        this.setState((s) => ({
          ...s,
          month: d.value as number,
          fullDate: newFullDate,
        }));
        break;
      case 'form-birth-year':
        newFullDate = moment(`${month}/${day}/${d.value}`).format(DATE_FORMAT);
        this.setState((s) => ({
          ...s,
          year: d.value as number,
          fullDate: newFullDate,
        }));
        break;
    }
    const validYear =
      moment(newFullDate).isValid() && moment().subtract(18, 'y').isAfter(moment(newFullDate));
    const underAge = moment().subtract(18, 'y').isBefore(moment(newFullDate));
    if (underAge) {
      this.setState((s) => ({ ...s, dateError: true }));
    } else {
      this.setState((s) => ({ ...s, dateError: false }));
    }
    const invalidDateError = !moment(newFullDate).isValid();
    return onValueChange(field.id, newFullDate, step, validYear, invalidDateError);
  };

  public BirthDay = (): JSX.Element => {
    const { day } = this.state;
    const localize = this.context;
    return (
      <>
        <DatePicker
          id='form-birth-day'
          selection={true}
          compact={true}
          fluid={true}
          options={this.lastDayOptions}
          value={day}
          placeholder={localize('dateDay')}
          onChange={this.onDateChange}
        />
      </>
    );
  };

  public BirthMonth = (): JSX.Element => {
    const { month } = this.state;
    const localize = this.context;
    return (
      <>
        <DatePicker
          id='form-birth-month'
          selection={true}
          compact={true}
          fluid={true}
          options={monthOptions}
          value={month}
          placeholder={localize('dateMonth')}
          onChange={this.onDateChange}
        />
      </>
    );
  };

  public BirthYear = (): JSX.Element => {
    const { year } = this.state;
    const localize = this.context;
    return (
      <>
        <DatePicker
          id='form-birth-year'
          selection={true}
          compact={true}
          fluid={true}
          options={this.yearOptions}
          value={year}
          placeholder={localize('dateYear')}
          onChange={this.onDateChange}
        />
      </>
    );
  };

  public render(): JSX.Element {
    const { field } = this.props;
    const { dateError } = this.state;
    const localize = this.context;

    return (
      <DateContainer>
        {dateError && <ErrorText>{localize('ageError')}</ErrorText>}
        <Label field={field} style={{ gridColumn: '1 / span 3' }} />
        <this.BirthMonth />
        <this.BirthDay />
        <this.BirthYear />
      </DateContainer>
    );
  }
}

export default BirthDayComponent;
