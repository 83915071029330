import React, { ReactElement } from 'react';
import { IFields, Steps } from '../../types/state';
import AddressComponent from './AddressComponent';
import AgeDeclaration from './AgeDeclaration';
import BirthDayComponent from './BirthDayComponent';
import CheckBoxField from './CheckBoxField';
import DateSelectField from './DateSelectField';
import DisciplineSelectorComponent from './DisciplineSelectorComponent';
import { DocumentUploader } from './DocumentUploader';
import DropdownComponent from './DropdownComponent';
import GenderComponent from './GenderComponent';
import Header from './HeaderComponent';
import IDUploadField from './IDUploadField';
import InputText from './InputText';
import MultiCheckBoxField from './MultiCheckboxField';
import MultiDropdownField from './MultiDropdownField';
import PrevEmployerComponent from './PrevEmployerComponent';
import PositionSelectorComponent from './PositionSelectorComponent';
import RecruiterSelectorComponent from './RecruiterSelectorComponent';
import SsnComponent from './SsnComponent';
import TransportMethod from './TransportMethod';
import TravelTimeField from './TravelTimeField';
import { HeightComponent } from './HeightComponent';
import { WeightComponent } from './WeightComponent';

interface IProps {
  field: IFields;
  step: Steps;
  value?: any;
  onValueChange?: (field: string, value: any, step: Steps, valid?: boolean, error?: boolean) => void;
}

function SubComponents({ field, step, value, onValueChange }: IProps): ReactElement {
  const findComponent = () => {
    switch (field.type) {
      case 'text':
        return <Header component={field} />;
      case 'textField':
        return <InputText field={field} value={value} step={step} onValueChange={onValueChange} />;
      case 'birthdayField':
        return (
          <BirthDayComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );
      case 'ssnField':
        return (
          <SsnComponent field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'addressField':
        return (
          <AddressComponent field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'genderSelector':
        return (
          <GenderComponent field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'dropdown':
      case 'primaryLanguageField':
      case 'hairColorSelector':
      case 'eyeColorSelector':
        return (
          <DropdownComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );
      case 'previousEmployersField':
        return (
          <PrevEmployerComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );
      case 'idUploadField':
        return (
          <IDUploadField field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'checkbox':
        return (
          <CheckBoxField field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'multiCheckbox':
        return (
          <MultiCheckBoxField
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );
      case 'ageDeclarationField':
        return (
          <AgeDeclaration field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'dateSelector':
        return (
          <DateSelectField field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'travelTimeField':
        return (
          <TravelTimeField field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'travelMethodSelector':
        return (
          <TransportMethod field={field} value={value} step={step} onValueChange={onValueChange} />
        );
      case 'multiDropdownField':
      case 'multiDropdown':
      case 'additionalLanguageField':
        return (
          <MultiDropdownField
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );
      case 'documentUploader':
        return <DocumentUploader field={field} step={step} onValueChange={onValueChange} />;

      case 'disciplineSelector':
        return (
          <DisciplineSelectorComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );

      case 'positionSelector':
        return (
          <PositionSelectorComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );

      case 'recruiterSelector':
        return (
          <RecruiterSelectorComponent
            field={field}
            value={value}
            step={step}
            onValueChange={onValueChange}
          />
        );

      case 'height':
        return <HeightComponent field={field} onValueChange={onValueChange} />;

      case 'weight':
        return <WeightComponent field={field} onValueChange={onValueChange} />;

      default:
        return <React.Fragment />;
    }
  };

  const subComponent = findComponent();

  return <>{subComponent}</>;
}

export default SubComponents;
