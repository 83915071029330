import React, { useEffect } from 'react';
import { ILayout } from '../backend';
import { IFields, IPageFields, Steps } from '../types/state';
import './AdditionalInformation.scss';
import FormPage from './FormPage';
import SubComponents from './subcomponents/SubComponents';
import { useLocalize } from '../context/localize';

interface IProps {
  layout?: ILayout;
  onValueChange: (field: string, value: any, step: Steps, valid?: boolean) => void;
  onSubmit: () => void;
  onCancel: () => void;
  setLanguage: (language: string) => void;
  languages: string[] | null;
  additionalInformation: IPageFields;
  valid: boolean;
  errorMessage?: string;
}

function AdditionalInformation({
  layout,
  additionalInformation,
  onValueChange,
  valid,
  onSubmit,
  onCancel,
  setLanguage,
  languages,
  errorMessage,
}: IProps) {
  const localize = useLocalize();

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <FormPage
      layout={layout}
      valid={valid}
      onSubmit={onSubmit}
      onCancel={onCancel}
      enablePrev={true}
      errorMessage={errorMessage}
      setLanguage={setLanguage}
      languages={languages}
    >
      <h3 className='form-header'>{localize('additionalInfoTitle')}</h3>
      <p className='form-title'>
        {localize('additionalInfoSubtitle')}
      </p>
      <div className='additional-info-container'>
        {additionalInformation.fields
          ?.filter((f: IFields) => f.visible)
          .map((f: IFields) => {
            const value = f.value;
            return (
              <SubComponents
                key={f.id}
                value={value}
                field={f}
                step='additionalInformation'
                onValueChange={onValueChange}
              />
            );
          })}
      </div>
    </FormPage>
  );
}

export default AdditionalInformation;
