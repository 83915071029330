import React, { useRef, useState } from 'react';
import { IFields, Steps, Value } from '../../types/state';
import Label from './Label';
import { InputTxt } from './InputText';
import { useLocalize } from '../../context/localize';

interface HeightComponentProps {
  field: IFields;
  onValueChange?: (id: string, value: string, step: Steps, valid: boolean) => void;
}

const parseHeight = (value?: Value) => {
  const regex = /^(\d+)’(\d+)”$/;
  let feet;
  let inches;

  if (value) {
    const match = regex.exec(value as string);

    if (match) {
      feet = parseInt(match[1], 10);
      inches = parseInt(match[2], 10);
    }
  }

  return { feet, inches };
};

export function HeightComponent({ field, onValueChange }: HeightComponentProps) {
  const localize = useLocalize();
  const formRef = useRef<HTMLFormElement>(null);
  const [valid, setValid] = useState(true);
  const { feet } = parseHeight(field.value);
  const { inches } = parseHeight(field.value);

  const handleOnInputChange = () => {
    const validForm = formRef.current?.checkValidity();
    const feetValue = (formRef.current?.elements[0] as HTMLInputElement).value;
    const inchesValue = (formRef.current?.elements[1] as HTMLInputElement).value;
    const heightValue = `${feetValue}’${inchesValue}”`;
    let missingValue = false;

    if ((feetValue || inchesValue) && (!feetValue || !inchesValue)) {
      missingValue = true;
    }

    if (onValueChange) {
      onValueChange('height', heightValue, 'basicInformation', !!validForm && !missingValue);
    }

    setValid(!!validForm && !missingValue);
  };

  return (
    <form ref={formRef} style={{ padding: '1rem', textAlign: 'start', width: '100%' }}>
      <Label field={field} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputTxt
          maxLength={1}
          onChange={handleOnInputChange}
          pattern='(^[0-9]+$|^$)'
          required={field.required}
          style={{ marginRight: '5px' }}
          type='text'
          valid={!!valid}
          value={feet}
        />
        <div style={{ marginRight: '15px' }}>{localize('unitFeet')}</div>
        <InputTxt
          maxLength={2}
          onChange={handleOnInputChange}
          pattern='^([0-9]|[1-9][0-9])$'
          required={field.required}
          style={{ marginRight: '5px' }}
          type='text'
          valid={!!valid}
          value={inches}
        />
        <div>{localize('unitInches')}</div>
      </div>
    </form>
  );
}
