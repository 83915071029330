import React, { ChangeEvent, useState } from 'react';
import { IFields, Steps } from '../../types/state';
import { InputTxt } from './InputText';
import Label from './Label';
import { useLocalize } from '../../context/localize';

interface WeightComponentProps {
  field: IFields;
  onValueChange?: (id: string, value: string, step: Steps, valid: boolean) => void;
}

export function WeightComponent({ field, onValueChange }: WeightComponentProps) {
  const localize = useLocalize();
  const [valid, setValid] = useState(true);
  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e;
    const { validity, value } = currentTarget;

    if (onValueChange) {
      onValueChange('weight', value, 'basicInformation', validity.valid);
    }

    setValid(validity.valid);
  };

  return (
    <div style={{ padding: '1rem', textAlign: 'start', width: '100%' }}>
      <Label field={field} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputTxt
          maxLength={3}
          onChange={handleOnInputChange}
          pattern='^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$'
          required={field.required}
          style={{ marginRight: '5px' }}
          type='text'
          valid={!!valid}
          value={field.value as string}
        />
        <div>{localize('unitPounds')}</div>
      </div>
    </div>
  );
}
