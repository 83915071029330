import React from 'react';
import styled from 'styled-components';
import { IFields } from '../../types/state';
import { useTranslation } from '../../context/language';

interface ILabelFields {
  label: string;
  required: boolean;
  translations?: Record<string, object>,
}

interface IProps {
  field: IFields | ILabelFields;
  style?: {};
}

const LabelContainer = styled.div`
  text-align: start;
`;

const LabelText = styled.p`
  display: inline-block;
  color: #4a4a4a;
  font-size: 12px;
  font-weight: bold;
  font-family: Lato, sans-serif;
  margin-bottom: 1rem !important;
  max-width: 100%;
  word-wrap: break-word;
`;

export const Asterisk = styled.p`
  display: inline-block;
  color: #f16778;
  font-size: 12px;
  font-weight: bold;
  margin-left: 4px;
  font-family: Lato, sans-serif;
`;

const Label = (props: IProps): JSX.Element => {
  const translation = useTranslation(props.field.translations);
  const { field, style } = props;
  const label = translation?.label || field.label;
  return (
    <LabelContainer style={style}>
      <LabelText>{label}</LabelText>
      {field.required && <Asterisk className='mandatory-sign'>*</Asterisk>}
    </LabelContainer>
  );
};

export default Label;
