import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { isValidEmail, isValidPhoneNumber } from '../../constants/validation';
import { IFields, IInputValidation, Steps } from '../../types/state';
import Label from './Label';
import { useTranslation } from '../../context/language';
import { useLocalize } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: string, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: any;
}

const InputContainer = styled.div`
  text-align: start;
  display: inline-block;
  padding: 1rem;
  width: 100%;
  grid-column-end: span 1;
  @media (max-width: 500px) {
    grid-column: 1;
  }
`;

export const InputTxt: StyledComponent<'input', any, IInputValidation, never> &
  IInputValidation = styled.input`
  border: none;
  height: 50px;
  background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  padding-left: 10px;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    font-weight: bold;
    font-family: Lato, sans-serif;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    border: none;
    background-color: ${(props: IInputValidation) => (props.valid ? '#f1f1f1' : '#fceff1')};
  }
`;

function InputText({ field, onValueChange = () => null, step, value }: IProps): ReactElement {
  const translation = useTranslation(field.translations);
  const localize = useLocalize();
  const [isValid, setValid] = useState(false);
  const [fieldValue, setValue] = useState('');
  const validateInput = useCallback(
    (newValue: string) => {
      let valid: boolean;
      switch (field.id) {
        case 'cellPhone':
        case 'homePhone':
          valid = isValidPhoneNumber(newValue);
          break;
        case 'email':
          valid = isValidEmail(newValue);
          break;
        default:
          valid = newValue.length > 0;
      }
      setValid(valid);
      return valid;
    },
    [field.id]
  );

  useEffect(() => {
    if (value) {
      setValue(value);
      onValueChange(field.id, value, step, validateInput(value));
    }
    return;
  }, [value, onValueChange, field.id, step, validateInput]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    onValueChange(field.id, newValue, step, validateInput(newValue));
  };

  let maxLength: number | undefined;

  switch (field.id) {
    case 'firstName':
    case 'middleName':
      maxLength = 25;
      break;
    case 'lastName':
    case 'email':
      maxLength = 50;
      break;
  }

  const label = translation?.label || field.label;

  return (
    <InputContainer id={field.id}>
      <Label field={field} />
      <div />
      <InputTxt
        id={field.id}
        type={field.id === 'email' ? 'email' : 'text'}
        value={fieldValue}
        valid={fieldValue ? isValid : true}
        onChange={handleChange}
        placeholder={`${localize('inputEnter')} ${label}`}
        maxLength={maxLength}
      />
    </InputContainer>
  );
}

export default InputText;
