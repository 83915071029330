import { FlagNameValues } from "semantic-ui-react";

export interface Language {
  code: string,
  flagCountryCode: FlagNameValues,
  name: string,
  englishName: string,
}

const languages = [
  {
    code: 'eng',
    flagCountryCode: 'us',
    name: 'English',
    englishName: 'English',
  },
  {
    code: 'spa',
    flagCountryCode: 'es',
    name: 'Español',
    englishName: 'Spanish',
  },
  {
    code: 'zho',
    flagCountryCode: 'cn',
    name: '中文 (简体)',
    englishName: 'Chinese (Simplified)',
  },
  {
    code: 'rus',
    flagCountryCode: 'ru',
    name: 'Русский',
    englishName: 'Russian',
  },
  {
    code: 'hat',
    flagCountryCode: 'ht',
    name: 'Kreyòl',
    englishName: 'Creole',
  },
  {
    code: 'fin',
    flagCountryCode: 'fi',
    name: 'Suomi',
    englishName: 'Finnish',
  },
] as Language[];

export default languages;

export const fromCode = languages.reduce<Record<string, Language>>((f, next) => {
  f[next.code] = next;
  return f;
}, {});
