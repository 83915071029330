import React, { createContext, useCallback, useContext } from "react";
import { useLanguage } from "./language";
import { Localization, localizations } from "../l10n";

export const LocalizeContext = createContext<(key: keyof Localization) => string>(() => { throw new Error('Context not initialized') });

export const LocalizeProvider = ({ children }: { children: JSX.Element }) => {
  const language = useLanguage();

  const localization = localizations[language as (null | keyof typeof localizations) || 'eng'] || localizations.eng;
  const localize = useCallback((key: keyof Localization) => localization[key], [localization])

  return (
    <LocalizeContext.Provider value={localize}>
      {children}
    </LocalizeContext.Provider>
  );
}

export const useLocalize = () => useContext(LocalizeContext);
