import { Localization } from ".";

const zho: Localization = {
  addressStreet: '街道地址（邮箱号码）',
  addressAptUnit: '公寓/单元号',
  addressCity: '城市',
  addressState: '州',
  addressZip: '邮政编码',
  ageDeclaration: '我声明我已年满 18 岁。',
  ageError: '您必须年满18岁方可继续',
  dateDay: '日',
  dateMonth: '月',
  dateYear: '年',
  dateSelect: '选择日期',
  documentUploadHeader: '将附件上传到您的申请表',
  documentRemove: '删除',
  documentChooseFile1: '拖放或',
  documentChooseFile2: '选择文件',
  documentChooseFile3: '上传',
  documentFormat: 'Microsoft Word, PDF, JPG, JPEG, PNG, GIF 格式的文档，最大5MB',
  documentError: '仅允许上传最大 5MB 的 Microsoft Word、PDF、JPG、JPEG、PNG、GIF 格式的文档。 请再试一次。',
  dropdownSelect: '请选择',
  experienceRemove: '删除',
  experienceAgencyName: '代理名称',
  experienceStartDate: '开始日期',
  experienceEndDate: '结束日期',
  experienceDatesApproximate: '可以是大概日期',
  genderSelect: '表明您的性别',
  genderMale: '男性',
  genderFemale: '女性',
  genderOther: '其他',
  genderRatherNotSay: '无可奉告',
  unitFeet: '尺',
  unitInches: '寸',
  unitPounds: '磅',
  unitHours: '小时',
  transportMethodSelect: '请选择您首选的出行方式',
  transportMethodWalking: '步行',
  transportMethodPublic: '公共交通',
  transportMethodDriving: '自驾',
  uploadIdButton: '上传证件',
  uploadIdEligible: '符合资格的证件形式包括：',
  uploadIdDrivers: '国家颁发的驾驶执照',
  uploadIdNonDrivers: '国家颁发的非驾驶员身份证',
  uploadIdGreenCard: '永久居民绿卡',
  inputEnter: '输入',
  prevEmployerPrompt: '填写您最近的代理雇主和就业日期',
  ssnWhy: '为什么需要提供社会安全号？',
  ssnExplanation: '为了您的安全并证明您的身份，需要社会安全号码。',

  formPagePrev: '上一步',
  formPageNext: '下一步',
  additionalInfoTitle: '您的信息',
  additionalInfoSubtitle: '请尽可能详细地填写以下联系信息',
  backgroundInfoTitle: '背景资料',
  backgroundInfoSubtitle: '添加一种身份形式以表明有资格在美国工作。',
  basicInfoTitle: '您的基本信息',
  basicInfoSubtitle: '要开始您的申请，请输入以下信息并点击“下一步”',
  basicInfoRequired: '* - 信息为必填项',
  scheduleWorkPrefTitle: '日程安排和工作偏好',
  shiftClientPrefTitle: '班次和客户偏好',
  shiftClientPrefSubtitle: '如果您符合我们的雇佣标准，请填写一些基本信息，以帮助我们确定最佳的轮班匹配。',
  verificationTitle: '我们已经找到了潜在的匹配护理员。',
  verificationSubtitle: '检查您的电子邮件或短信中是否有唯一的 6 位数代码以确认您的身份',
  verificationResend: '重新发送验证码',
  welcomeTitle: '感谢您对以下职位的兴趣',
  welcomeGetStarted: '开始',
  experienceTitle: '工作经验',
  experienceSubtitle: '您有多少年相关工作经验？',
};

export default zho;
