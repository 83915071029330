import React from 'react';
import { ReactComponent as FinishCheckmark } from '../images/FinishCheckmark.svg';
import { FormPageProps } from '../types/props';
import { IFields, IPageFields } from '../types/state';
import './FinishPage.scss';
import FormPage from './FormPage';
import SubComponents from './subcomponents/SubComponents';

interface IProps {
  agencyName: string;
  completion: IPageFields;
}

class FinishPage extends React.PureComponent<FormPageProps & IProps> {
  public render(): JSX.Element {
    const { completion } = this.props;
    return (
      <FormPage layout={this.props.layout} noButtons={true}>
        <div className='finish-container'>
          <FinishCheckmark className='finish-checkmark' />
          {!!completion.fields?.length &&
            completion.fields
              .filter((f: IFields) => f.visible)
              .map((f: IFields) => <SubComponents key={f.id} field={f} step='completionPage' />)}
        </div>
      </FormPage>
    );
  }
}

export default FinishPage;
