import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import { useTranslation } from '../../context/language';
import { useLocalize } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: DropdownType, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: any;
}

interface IOption {
  value?: string | number | boolean | Array<string | number | boolean>;
  text: string;
}

type DropdownType = string | number | boolean | Array<string | number | boolean> | undefined;

const DropdownField = styled(Dropdown)`
  &&&& {
    font-family: Lato, sans-serif;
    border: 1px solid #c8cbd3;
    width: 100%;
  }
  &&&& > i {
    border-left: 1px solid #c8cbd3;
    color: #c8cbd3;
  }
  &&& .text {
    max-width: ${(props) =>
      props.step === 'basicInformation' || props.step === 'additionalInformation'
        ? '200px'
        : '450px'};
    padding-right: 1rem;
  }
`;

const DropdownContainer = styled.div`
  text-align: left;
  width: 100%;
  padding: 1rem;
`;

function PositionSelectorComponent({
  field,
  onValueChange = () => null,
  step,
  value,
}: IProps): ReactElement {
  const translation = useTranslation(field.translations);
  const localize = useLocalize();
  const [selectedValue, setValue] = useState<DropdownType>(undefined);
  const [valueOptions, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (value) {
      setValue(value);
    }
    if (field?.options?.length) {
      const { options } = field;
      const newOptions = options.map((o, i) => {
        const text = translation?.options?.[i] || o;
        const optionObject: IOption = {
          value: o,
          text,
        };
        return optionObject;
      });
      return setOptions(newOptions);
    }
    return;
  }, [value, field, translation]);

  const onSelectionChange = (_e: SyntheticEvent, d: DropdownProps) => {
    setValue(d.value);
    onValueChange(field.id, d.value, step, !!d.value);
  };

  return (
    <DropdownContainer>
      <Label field={field} />
      <div />
      <DropdownField
        id={`dropdown-${field.id}`}
        selection={true}
        compact={true}
        search={true}
        options={valueOptions}
        value={selectedValue}
        placeholder={localize('dropdownSelect')}
        onChange={onSelectionChange}
        step={step}
        clearable={true}
      />
    </DropdownContainer>
  );
}

export default PositionSelectorComponent;
