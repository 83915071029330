import { Localization } from ".";

const spa: Localization = {
  addressStreet: 'Dirección de la calle (sin apartados de correos)',
  addressAptUnit: 'Apt/Unidad #',
  addressCity: 'Ciudad',
  addressState: 'Estado',
  addressZip: 'Código postal',
  ageDeclaration: 'Declaro que tengo al menos 18 años de edad.',
  ageError: 'Debes ser mayor de 18 años para continuar',
  dateDay: 'Día',
  dateMonth: 'Mes',
  dateYear: 'Año',
  dateSelect: 'seleccione fecha',
  documentUploadHeader: 'Subir archivos adjuntos a su aplicación',
  documentRemove: 'Eliminar',
  documentChooseFile1: 'Arrastrar y soltar o',
  documentChooseFile2: 'Elegir archivo',
  documentChooseFile3: 'para cargar',
  documentFormat: 'Microsoft Word, PDF, JPG, JPEG, PNG, archivo GIF de hasta 5 MB',
  documentError: 'Solo se permite cargar un documento en formato Microsoft Word, PDF, JPG, JPEG, PNG, GIF de hasta 5 MB. Inténtalo de nuevo.',
  dropdownSelect: 'Seleccione',
  experienceRemove: 'Eliminar',
  experienceAgencyName: 'Nombre de agencia',
  experienceStartDate: 'Fecha de inicio',
  experienceEndDate: 'Fecha final',
  experienceDatesApproximate: 'Las fechas pueden ser aproximadas.',
  genderSelect: 'Indica tu género',
  genderMale: 'Masculino',
  genderFemale: 'Femenino',
  genderOther: 'Otro',
  genderRatherNotSay: 'Prefiero no decirlo',
  unitFeet: 'pie',
  unitInches: 'pulgadas',
  unitPounds: 'libra',
  unitHours: 'horas',
  transportMethodSelect: 'Seleccione su método de transporte preferido',
  transportMethodWalking: 'caminando',
  transportMethodPublic: 'público',
  transportMethodDriving: 'conduciendo',
  uploadIdButton: 'Cargar identificación',
  uploadIdEligible: 'Las formas elegibles de identificación incluyen:',
  uploadIdDrivers: 'Licencia de conducir emitida por el estado',
  uploadIdNonDrivers: 'Identificación de no conductor emitida por el estado',
  uploadIdGreenCard: 'Tarjeta Verde para Residentes Permanentes',
  inputEnter: 'Ingrese',
  prevEmployerPrompt: 'Complete sus empleadores de agencia más recientes y las fechas de empleo',
  ssnWhy: '¿Porqué necesitamos esto?',
  ssnExplanation: 'El Número de Seguro Social es necesario para su seguridad y para probar su identidad',

  formPagePrev: 'Anterior',
  formPageNext: 'Siguiente',
  additionalInfoTitle: 'Tu información',
  additionalInfoSubtitle: 'Complete la mayor cantidad posible de la siguiente información de contacto',
  backgroundInfoTitle: 'Información de contexto',
  backgroundInfoSubtitle: 'Agregue una forma de identidad para mostrar la elegibilidad para trabajar en los Estados Unidos.',
  basicInfoTitle: 'Su información básica',
  basicInfoSubtitle: 'Para comenzar su aplicación, ingrese la información a continuación y presione "Siguiente"',
  basicInfoRequired: '* - se requiere información',
  scheduleWorkPrefTitle: 'Preferencias de horario y trabajo',
  shiftClientPrefTitle: 'Turno y Preferencias del Cliente',
  shiftClientPrefSubtitle: 'Complete cierta información básica para ayudarnos a determinar las mejores coincidencias de turno si cumple con nuestros criterios de empleo.',
  verificationTitle: 'Hemos encontrado posibles coincidencias de cuidadores.',
  verificationSubtitle: 'Revise su correo electrónico o mensajes de texto para obtener su código único de 6 dígitos para confirmar su identidad',
  verificationResend: 'Reenviar codigo',
  welcomeTitle: 'Gracias por su interés en un puesto en',
  welcomeGetStarted: 'Empezar',
  experienceTitle: 'Experiencia laboral',
  experienceSubtitle: '¿Cuántos años de experiencia laboral relevante tienes?',
};

export default spa;
