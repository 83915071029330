import moment from 'moment';
import React, { ReactElement, SyntheticEvent } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Label from './Label';
import { useLocalize } from '../../context/localize';

interface IProps {
  handleValueChange: (agency: IExperience) => void;
  id: number;
  experience: IExperience;
  removeExperience: (id: number) => void;
}

export interface IExperience {
  id: number;
  agencyName: string;
  start?: string | null;
  end?: string | null;
}

const LinkButton = styled.button`
  outline: none;
  background-color: transparent;
  font-size: 12px;
  border: none;
  color: #04a5d5;
  text-align: center;
  margin: 0;
  cursor: pointer;
  justify-self: flex-end;
  grid-area: 'remove';
`;

const ExperienceContainer = styled.div`
  text-align: start;
  display: grid;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.125);
  column-gap: 1rem;
  border-radius: 0.75rem;
  grid-template-areas:
    'null null remove'
    'agName agName agName'
    'start end approx';
  padding: 1.5rem;
  margin: 1rem 0 1rem 0;
  width: 100%;
  @media (max-width: 500px) {
    grid-template-areas:
      'null remove'
      'agName agName'
      'start end'
      'approx approx';
  }
`;

const BoxDatePicker = styled(DatePicker)`
  border: none;
  height: 45px;
  width: 90px;
  background-color: #f1f1f1;
  padding-left: 0.5rem;
  border-radius: 4px;
`;

const InputField = styled.input`
  border: none;
  height: 50px;
  background-color: #f1f1f1;
  padding: 0 1rem 0 1rem;
  border-radius: 4px;
  width: 100%;
  &::placeholder {
    font-weight: bold;
    font-family: Lato, sans-serif;
    color: #c8cbd3;
  }
  &:focus {
    outline: none;
    border: none;
    background-color: #f1f1f1;
  }
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
  grid-area: ${(props) => props.id};
`;

function ExperienceCard({
  handleValueChange,
  id,
  experience,
  removeExperience,
}: IProps): ReactElement {
  const localize = useLocalize();
  const { start, end } = experience;

  const handleAgencyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    return handleValueChange({
      id,
      agencyName: value,
      start: experience.start,
      end: experience.end,
    });
  };

  const handleStartChange = (date: Date | null, _event: SyntheticEvent<any, Event> | undefined) => {
    const formattedStart = date ? moment(date).format('MM/DD/YYYY') : null;
    return handleValueChange({
      id,
      agencyName: experience.agencyName,
      start: formattedStart,
      end: experience.end,
    });
  };

  const handleEndChange = (date: Date | null, _event: SyntheticEvent<any, Event> | undefined) => {
    const formattedEnd = date ? moment(date).format('MM/DD/YYYY') : null;
    return handleValueChange({
      id,
      agencyName: experience.agencyName,
      start: experience.start,
      end: formattedEnd,
    });
  };

  const handleRemove = () => {
    return removeExperience(id);
  };

  return (
    <ExperienceContainer className='prev-employer-card'>
      <div style={{ gridArea: 'null' }} />
      <LinkButton onClick={handleRemove}>{localize('experienceRemove')}</LinkButton>
      <InputContainer id='agName'>
        <Label field={{ label: localize('experienceAgencyName'), required: false }} />
        <br />
        <InputField
          id='agName'
          type='text'
          value={experience.agencyName}
          onChange={handleAgencyNameChange}
        />
      </InputContainer>
      <InputContainer id='start'>
        <Label field={{ label: localize('experienceStartDate'), required: false }} />
        <br />
        <BoxDatePicker
          id='start'
          dateFormat='MM/yyyy'
          showMonthYearPicker={true}
          selected={start ? moment(start).toDate() : null}
          maxDate={end ? moment(end).toDate() : null}
          placeholderText={localize('dateSelect')}
          onChange={handleStartChange}
        />
      </InputContainer>
      <InputContainer id='end'>
        <Label field={{ label: localize('experienceEndDate'), required: false }} />
        <br />
        <BoxDatePicker
          id='end'
          dateFormat='MM/yyyy'
          showMonthYearPicker={true}
          minDate={start ? moment(start).toDate() : null}
          selected={end ? moment(end).toDate() : null}
          placeholderText={localize('dateSelect')}
          onChange={handleEndChange}
        />
      </InputContainer>
      <p
        style={{
          gridArea: 'approx',
          margin: '2rem 0 0 0',
          textAlign: 'center',
          alignSelf: 'center',
          color: '#9b9b9b',
          fontSize: '11px',
        }}
      >
        {localize('experienceDatesApproximate')}
      </p>
    </ExperienceContainer>
  );
}

export default ExperienceCard;
