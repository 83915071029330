import React, { ReactElement, useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import './Slider.scss';
import { useLocalize } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: number, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: number;
}

const TravelTimeContainer = styled.div`
  padding: 1rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 1 / -1;
  width: 100%;
  margin-bottom: 3rem;
  max-width: 1200px;
`;

function TravelTimeField({ step, field, value, onValueChange = () => null }: IProps): ReactElement {
  const localize = useLocalize();
  const [sliderValue, setSliderValue] = useState(value || 0);

  useEffect(() => {
    if (value)
      return () => {
        setSliderValue(value);
      };
    return;
  }, [value]);

  const handleChange = (e: any) => {
    setSliderValue(e);
    onValueChange(field.id, e, step, true);
  };

  const rangeLabels = (time: number) => {
    return `${time} ${localize('unitHours')}`;
  };

  return (
    <TravelTimeContainer>
      <Label field={field} />
      <div style={{ padding: '1rem 0' }} />
      <InputRange
        maxValue={20}
        minValue={0}
        onChange={handleChange}
        value={sliderValue}
        formatLabel={rangeLabels}
      />
    </TravelTimeContainer>
  );
}

export default TravelTimeField;
