import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import { useLocalize } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: string | null, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: string | null;
}

const DateContainer = styled.div`
  display: grid;
  text-align: center;
  justify-items: start;
  grid-template-columns: 1fr;
  padding: 1rem;
  align-items: start;
  width: 100%;
`;

export const BoxDatePicker = styled(DatePicker)`
  border: none;
  height: 45px;
  background-color: #f1f1f1;
  padding-left: 1rem;
  border-radius: 4px;
`;

function DateSelectField({ field, step, value, onValueChange = () => null }: IProps): ReactElement {
  const localize = useLocalize();
  const dateProp = value ? moment(value).toDate() : null;
  const [selectedDate, setDate] = useState(dateProp);

  const handleChange = (
    date: Date | null,
    _event: React.SyntheticEvent<any, Event> | undefined
  ) => {
    const formattedDate = date ? moment(date).format('MM/DD/YYYY') : null;
    setDate(date);
    onValueChange(field.id, formattedDate, step, !!date);
  };

  return (
    <DateContainer>
      <Label field={field} />
      <BoxDatePicker
        id='end'
        selected={selectedDate}
        placeholderText={localize('dateSelect')}
        onChange={handleChange}
      />
    </DateContainer>
  );
}

export default DateSelectField;
