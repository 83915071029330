// This file is used to programmatically generate the other files,
// so we want strings to stay on one line.
// tslint:disable:max-line-length
import { Localization } from ".";

const eng: Localization = {
  addressStreet: 'Street Address (No P.O. Boxes)',
  addressAptUnit: 'Apt/Unit #',
  addressCity: 'City',
  addressState: 'State',
  addressZip: 'Zip',
  ageDeclaration: 'I declare that I am at least 18 years of age.',
  ageError: 'You must be over 18yrs old to continue',
  dateDay: 'Day',
  dateMonth: 'Month',
  dateYear: 'Year',
  dateSelect: 'Select date',
  documentUploadHeader: 'Upload attachments to your application',
  documentRemove: 'Remove',
  documentChooseFile1: 'Drag & Drop or',
  documentChooseFile2: 'Choose file',
  documentChooseFile3: 'to upload',
  documentFormat: 'Microsoft Word, PDF, JPG, JPEG, PNG, GIF file up to 5MB',
  documentError: 'Only a Microsoft Word, PDF, JPG, JPEG, PNG, GIF formatted document up to 5MB is allowed to be uploaded. Please try again.',
  dropdownSelect: 'Please Select',
  experienceRemove: 'Remove',
  experienceAgencyName: 'Agency Name',
  experienceStartDate: 'Start Date',
  experienceEndDate: 'End Date',
  experienceDatesApproximate: 'Dates can be approximate',
  genderSelect: 'Indicate your gender',
  genderMale: 'Male',
  genderFemale: 'Female',
  genderOther: 'Other',
  genderRatherNotSay: 'Rather not say',
  unitFeet: 'ft',
  unitInches: 'in',
  unitPounds: 'lb',
  unitHours: 'hours',
  transportMethodSelect: 'Select Your Preferred Transport Method',
  transportMethodWalking: 'walking',
  transportMethodPublic: 'public',
  transportMethodDriving: 'driving',
  uploadIdButton: 'Upload ID',
  uploadIdEligible: 'Eligible forms of id include:',
  uploadIdDrivers: 'State issued drivers license',
  uploadIdNonDrivers: 'State issued non-drivers id',
  uploadIdGreenCard: 'Permanent Residents Green Card',
  inputEnter: 'Enter',
  prevEmployerPrompt: 'Fill in your most recent agency employers and dates of employment',
  ssnWhy: 'Why do we need this?',
  ssnExplanation: 'Social Security Number is needed for your security and to prove your identity',

  formPagePrev: 'Prev',
  formPageNext: 'Next',
  additionalInfoTitle: 'Your Information',
  additionalInfoSubtitle: 'Please complete as much of the following contact information as possible',
  backgroundInfoTitle: 'Background Information',
  backgroundInfoSubtitle: 'Add a form of identity to show eligibility to work in the United States.',
  basicInfoTitle: 'Your Basic Information',
  basicInfoSubtitle: 'To start your application please enter the information below and hit “Next”',
  basicInfoRequired: '* - information is required',
  scheduleWorkPrefTitle: 'Schedule and Work Preferences',
  shiftClientPrefTitle: 'Shift and Client Preferences',
  shiftClientPrefSubtitle: 'Please fill out some basic information to help us determine the best shift matches should you meet our employment criteria.',
  verificationTitle: 'We’ve found potential caregiver matches.',
  verificationSubtitle: 'Check your email or text messages for your unique 6-digit code to confirm your identity',
  verificationResend: 'Resend Code',
  welcomeTitle: 'Thank you for your interest in a position at',
  welcomeGetStarted: 'Get Started',
  experienceTitle: 'Work Experience',
  experienceSubtitle: 'How many years of relevant job experience do you have?',
};

export default eng;
