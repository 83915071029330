import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// tslint:disable-next-line: ordered-imports
import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './components/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
