import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import { useTranslation } from '../../context/language';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: DropdownType, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: DropdownType;
}

interface IOption {
  value: DropdownType;
  text: string;
}

type DropdownType = string | number | boolean | Array<string | number | boolean> | undefined;

const DropdownField = styled(Dropdown)`
  &&&& {
    font-family: Lato, sans-serif;
    border: 1px solid #c8cbd3;
    width: 100%;
  }
  &&&& > i {
    border-left: 1px solid #c8cbd3;
    color: #c8cbd3;
  }
`;

const DropdownContainer = styled.div`
  text-align: left;
  width: 100%;
  padding: 1rem;
  height: 100%;
`;

function DisciplineSelectorField({
  field,
  onValueChange = () => null,
  step,
  value,
}: IProps): ReactElement {
  const translation = useTranslation(field.translations);
  const [selectedValue, setValue] = useState<DropdownType>([]);
  const [valueOptions, setOptions] = useState<IOption[]>([]);

  useEffect(() => {
    if (value) {
      setValue(value);
    }
    if (field?.options?.length) {
      const { options } = field;
      const newOptions = options.map((o) => {
        const optionObject: IOption = {
          value: o,
          text: o,
        };
        return optionObject;
      }).sort((a, b) => a.text.localeCompare(b.text));
      return setOptions(newOptions);
    }
    return;
  }, [value, field]);

  const onSelectionChange = (_e: SyntheticEvent, d: DropdownProps & DropdownType) => {
    setValue(d.value);
    onValueChange(field.id, d.value, step, !!d.value);
  };

  const label = translation?.label || field.label;

  return (
    <DropdownContainer>
      <Label field={field} />
      <div />
      <DropdownField
        id={`dropdown-${field.id}`}
        selection={true}
        search={true}
        compact={true}
        multiple={true}
        options={valueOptions}
        value={selectedValue}
        placeholder={label}
        onChange={onSelectionChange}
      />
    </DropdownContainer>
  );
}

export default DisciplineSelectorField;
