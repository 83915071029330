type Config = {
  API: string;
};

const env: {
  [key: string]: Config;
} = {
  local: {
    API: 'http://localhost:3000',
  },
  dev: {
    API: 'https://api-at-dev.gothamcare.com',
  },
  prod: {
    API: 'https://api-at.careconnectmobile.com',
  },
};

export default env[process.env.REACT_APP_STAGE || 'local'];
