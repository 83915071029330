import { Localization } from ".";

const rus: Localization = {
  addressStreet: 'Адрес улицы (без абонентских ящиков)',
  addressAptUnit: 'Кв/блок #',
  addressCity: 'Город',
  addressState: 'Состояние',
  addressZip: 'Почтовый индекс',
  ageDeclaration: 'Я заявляю, что мне исполнилось 18 лет.',
  ageError: 'Вам должно быть больше 18 лет, чтобы продолжить',
  dateDay: 'День',
  dateMonth: 'Месяц',
  dateYear: 'Год',
  dateSelect: 'выберите дату',
  documentUploadHeader: 'Загрузите вложения в ваше приложение',
  documentRemove: 'Удалять',
  documentChooseFile1: 'Перетащите или',
  documentChooseFile2: 'Выберите файл',
  documentChooseFile3: 'для загрузки',
  documentFormat: 'Microsoft Word, PDF, JPG, JPEG, PNG, файл GIF до 5 МБ',
  documentError: 'Разрешается загружать только документы в формате Microsoft Word, PDF, JPG, JPEG, PNG, GIF размером до 5 МБ. Пожалуйста, попробуйте еще раз.',
  dropdownSelect: 'Пожалуйста выберите',
  experienceRemove: 'Удалять',
  experienceAgencyName: 'Название агентства',
  experienceStartDate: 'Дата начала',
  experienceEndDate: 'Дата окончания',
  experienceDatesApproximate: 'Даты могут быть приблизительными',
  genderSelect: 'Укажите свой пол',
  genderMale: 'Мужской',
  genderFemale: 'Женский',
  genderOther: 'Другой',
  genderRatherNotSay: 'Предпочитаю умолчать',
  unitFeet: 'футов',
  unitInches: 'дюймы',
  unitPounds: 'фунт',
  unitHours: 'часы',
  transportMethodSelect: 'Выберите предпочитаемый способ транспортировки',
  transportMethodWalking: 'гулять пешком',
  transportMethodPublic: 'публичный',
  transportMethodDriving: 'вождение',
  uploadIdButton: 'Загрузить идентификатор',
  uploadIdEligible: 'Допустимые формы идентификатора включают:',
  uploadIdDrivers: 'Водительское удостоверение государственного образца',
  uploadIdNonDrivers: 'Удостоверение личности без права вождения, выданное государством',
  uploadIdGreenCard: 'Зеленая карта для постоянных жителей',
  inputEnter: 'Введите',
  prevEmployerPrompt: 'Заполните ваши последние работодатели агентства и даты трудоустройства',
  ssnWhy: 'Зачем нам это надо?',
  ssnExplanation: 'Номер социального страхования необходим для вашей безопасности и для подтверждения вашей личности.',

  formPagePrev: 'Пред.',
  formPageNext: 'Далее',
  additionalInfoTitle: 'Ваша информация',
  additionalInfoSubtitle: 'Пожалуйста, заполните как можно больше следующей контактной информации',
  backgroundInfoTitle: 'Исходная информация',
  backgroundInfoSubtitle: 'Добавьте форму удостоверения личности, чтобы показать право на работу в Соединенных Штатах.',
  basicInfoTitle: 'Ваша основная информация',
  basicInfoSubtitle: 'Чтобы запустить приложение, введите информацию ниже и нажмите «Далее».',
  basicInfoRequired: '* - информация обязательна',
  scheduleWorkPrefTitle: 'Расписание и рабочие предпочтения',
  shiftClientPrefTitle: 'Настройки смены и клиента',
  shiftClientPrefSubtitle: 'Пожалуйста, заполните основную информацию, чтобы помочь нам определить наиболее подходящие смены, если вы соответствуете нашим критериям трудоустройства.',
  verificationTitle: 'Мы нашли потенциальных опекунов.',
  verificationSubtitle: 'Проверьте свою электронную почту или текстовые сообщения на наличие уникального 6-значного кода для подтверждения вашей личности.',
  verificationResend: 'Отправить код еще раз',
  welcomeTitle: 'Благодарим Вас за интерес к вакансии в',
  welcomeGetStarted: 'Начать',
  experienceTitle: 'Опыт работы',
  experienceSubtitle: 'Сколько лет релевантного опыта работы у вас есть?',
};

export default rus;
