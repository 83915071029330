import eng from "./eng";
import hat from "./hat";
import rus from "./rus";
import spa from "./spa";
import zho from "./zho";

export type Localization = Record<
  | 'addressStreet'
  | 'addressAptUnit'
  | 'addressCity'
  | 'addressState'
  | 'addressZip'
  | 'ageDeclaration'
  | 'ageError'
  | 'dateDay'
  | 'dateMonth'
  | 'dateYear'
  | 'dateSelect'
  | 'documentUploadHeader'
  | 'documentRemove'
  | 'documentChooseFile1'
  | 'documentChooseFile2'
  | 'documentChooseFile3'
  | 'documentFormat'
  | 'documentError'
  | 'dropdownSelect'
  | 'experienceRemove'
  | 'experienceAgencyName'
  | 'experienceStartDate'
  | 'experienceEndDate'
  | 'experienceDatesApproximate'
  | 'genderSelect'
  | 'genderMale'
  | 'genderFemale'
  | 'genderOther'
  | 'genderRatherNotSay'
  | 'unitFeet'
  | 'unitInches'
  | 'unitPounds'
  | 'unitHours'
  | 'transportMethodSelect'
  | 'transportMethodWalking'
  | 'transportMethodPublic'
  | 'transportMethodDriving'
  | 'uploadIdButton'
  | 'uploadIdEligible'
  | 'uploadIdDrivers'
  | 'uploadIdNonDrivers'
  | 'uploadIdGreenCard'
  | 'inputEnter'
  | 'prevEmployerPrompt'
  | 'ssnWhy'
  | 'ssnExplanation'

  | 'formPagePrev'
  | 'formPageNext'
  | 'additionalInfoTitle'
  | 'additionalInfoSubtitle'
  | 'backgroundInfoTitle'
  | 'backgroundInfoSubtitle'
  | 'basicInfoTitle'
  | 'basicInfoSubtitle'
  | 'basicInfoRequired'
  | 'scheduleWorkPrefTitle'
  | 'shiftClientPrefTitle'
  | 'shiftClientPrefSubtitle'
  | 'verificationTitle'
  | 'verificationSubtitle'
  | 'verificationResend'
  | 'welcomeTitle'
  | 'welcomeGetStarted'
  | 'experienceTitle'
  | 'experienceSubtitle'
  , string>;

export const localizations = {
  eng,
  hat,
  rus,
  spa,
  zho,
};
