import React from "react";
import styled from "styled-components";
import { Language } from "../../constants/languages";

const LanguageCardContainer = styled.div`
  display: grid;
  grid-column: span 1;
  grid-template-columns: 1fr;
  place-items: center;
  box-shadow: 0 0 1rem #00000025;
  border-radius: 10px;
  height: 200px;
  width: 200px;
  padding: 2rem;
  margin: 1rem 0 1rem 0;
  cursor: pointer;
`;

const StyledLabel = styled.div`
  text-align: center;
  margin-top: 0.5rem;
`;

const NativeLabel = styled.div`
  display: block;
  font-size: 1.2rem;
`;

const EnglishLabel = styled.div`
  display: block;
  font-size: 1rem;
  margin-top: 0.3rem;
  opacity: 0.5;
`;

const Flag = styled.img`
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.8rem;
  border-radius: 50%;
`;

interface IProps {
  language: Language;
  onClick: () => void;
}

function LanguageCard({
  language,
  onClick,
}: IProps) {
  return (
    <LanguageCardContainer onClick={onClick}>
      <Flag src={`flags/${language.flagCountryCode}.png`} />
      <StyledLabel>
        <NativeLabel>{language.name}</NativeLabel>
        <EnglishLabel>{language.englishName}</EnglishLabel>
      </StyledLabel>
    </LanguageCardContainer>
  );
}

export default LanguageCard;