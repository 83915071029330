import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IFields, Steps } from '../../types/state';
import Label from './Label';
import { useLocalize } from '../../context/localize';

interface IProps {
  field: IFields;
  onValueChange?: (id: string, value: string, step: Steps, valid: boolean) => void;
  step: Steps;
  value?: string;
}

const TransportContainer = styled.div`
  text-align: start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 1 / -1;
  grid-template-rows: 1fr 1fr;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  place-items: center;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
  }
`;

const SelectionContainer = styled.div`
  text-align: center;
  display: inline-block;
  padding: 1rem;
  @media (max-width: 500px) {
    display: block;
    padding: 0;
  }
`;

const TransportText = styled.div`
  display: inline-block;
  padding: 0 1rem;
  margin: 0;
  @media (max-width: 500px) {
    display: block;
  }
`;

function TransportMethod({ field, step, value, onValueChange = () => null }: IProps): ReactElement {
  const localize = useLocalize();
  const [selected, setSelected] = useState(value || '');

  useEffect(() => {
    if (value)
      return () => {
        setSelected(value);
      };
    return;
  }, [value]);

  const onMethodChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const method = e.currentTarget.id;
    setSelected(method);
    onValueChange(field.id, method, step, true);
  };

  const getTransport = (method: string) => {
    const color = method === selected ? 'blue' : 'grey';
    return `/icon_${method}${color}.svg`;
  };

  return (
    <TransportContainer>
      <Label
        field={{ label: localize('transportMethodSelect'), required: field.required }}
        style={{ gridColumn: '1 / span 3', placeSelf: 'center' }}
      />
      <SelectionContainer id='walking' onClick={onMethodChange}>
        <img alt='transport-walking' src={getTransport('walking')} />
        <TransportText>{localize('transportMethodWalking')}</TransportText>
      </SelectionContainer>
      <SelectionContainer id='public' onClick={onMethodChange}>
        <img alt='transport-public' src={getTransport('public')} />
        <TransportText>{localize('transportMethodPublic')}</TransportText>
      </SelectionContainer>
      <SelectionContainer id='driving' onClick={onMethodChange}>
        <img alt='transport-driving' src={getTransport('driving')} />
        <TransportText>{localize('transportMethodDriving')}</TransportText>
      </SelectionContainer>
    </TransportContainer>
  );
}

export default TransportMethod;
