import { IFieldsValue } from '../backend';

/**
 * agencyWelcomeNote configuration uses following IDs for fields
 *  - recruiter, discipline, positionApplyingFor
 *
 * But app tracking BE expects them to be recruiter, disciplines, appliedFor
 */
export const mapWelcomePageFields = (params: IFieldsValue) => {
  return {
    recruiter: params.recruiter,
    disciplines: Array.isArray(params.discipline) ? params.discipline : null,
    appliedFor: params.positionApplyingFor,
  }
}